<template>

    <!-- NOTE: We manually map data to vue components here -->

    <ContentPopup v-if="contentComponentData && contentComponentData.type === 'contentPopup'" 
      :tabs="contentComponentData.data.tabs" 
      @close="closeContentComponent"/>

    <ContentPopup2 v-if="contentComponentData && contentComponentData.type === 'contentPopup2'"
      :heading="contentComponentData.data.heading"
      :headingZh="contentComponentData.data.headingZh"
      :headingPt="contentComponentData.data.headingPt"
      :slides="contentComponentData.data.slides"
      @close="closeContentComponent"/>

    <transition name="fade" mode="out-in">
      <DeepData v-if="contentComponentData && contentComponentData.type === 'deepData'"
      :dataFields="contentComponentData.data.dataFields"
      :currentSceneId="currentSceneId"
      @close="closeContentComponent"/>
    </transition>

</template>

<script>
import ContentPopup from '@/components/content-components/ContentPopup';
import ContentPopup2 from '@/components/content-components/ContentPopup2';
import DeepData from '@/components/content-components/DeepData';
import {closeComponentIfOpen} from '@/helpers/navigationHelper';

export default {
  name: 'ContentComponentRenderer',
  components: {
    ContentPopup,
    ContentPopup2,
    DeepData,
  },
  props: {
      contentComponentData: Object,
      currentSceneId: Number
  },
  created()
  {
  },
  methods:
  {
    closeContentComponent()
    {
      closeComponentIfOpen();
    }
  }
}
</script>
