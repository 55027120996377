<template>

    <div class="profile-selection">

        <HotspotButton class="hotspot-button--uppercase hotspot-button--multiline" :x="344" :y="694" :title="s_('profileSelection.theEnthusiastHotspot')" type="clickable" @click="selectProfile('theEnthusiast')" color="red" icon="arrow" direction="right" :isOpen="selectedProfile === 'theEnthusiast'" v-show="(!selectedProfile || selectedProfile === 'theEnthusiast')"/>

        <HotspotButton class="hotspot-button--uppercase hotspot-button--multiline" :x="1420" :y="736" :title="s_('profileSelection.easyHealthHotspot')" type="clickable" @click="selectProfile('easyHealth')" color="blue" icon="arrow" direction="right" :isOpen="selectedProfile === 'easyHealth'" v-show="(!selectedProfile || selectedProfile === 'easyHealth')"/>

        <HotspotButton class="hotspot-button--uppercase hotspot-button--multiline" :x="1770" :y="640" :title="s_('profileSelection.healthyFeelGoodsHotspot')" type="clickable" @click="selectProfile('healthyFeelGoods')" color="yellow" icon="arrow" direction="right" :isOpen="selectedProfile === 'healthyFeelGoods'" v-show="(!selectedProfile || selectedProfile === 'healthyFeelGoods')"/>

        <div class="profile-selection__buttons">
            <div class="button profile-selection__button" @click="goToPathByID(whyDataMattersLink)" v-html="s_('profileSelection.whyDataMatters')"></div>
            <div class="button profile-selection__button" @click="goToPathByID(methodologyLink)" v-html="s_('profileSelection.whyMethodology')"></div>
        </div>

        <div class="profile-selection__info" :class="'profile-selection__info--'+selectedProfile">

            <template v-if="selectedProfile === 'theEnthusiast'">
                <div class="profile-selection__segment-heading" v-html="getSegmentHeading('enthusiast')"></div>
                <div class="profile-selection__segment-text" v-html="getSegmentText('enthusiast')"></div>
                <div class="profile-selection__footer">
                    <div class="profile-selection__segment-stats">
                        <div class="profile-selection__segment-stats-heading" v-html="s_('profilesPopup.demographicHeading')"></div>
                        <div class="profile-selection__segment-stats-text" v-html="getSegmentDemographicText('enthusiast')"></div>
                    </div>
                    <div class="profile-selection__segment-button" @click="goToPathByID(theEnthusiastsLinkEn)" v-html="s_('profileSelection.meetEnthusiastBtn')"></div>
                    <div class="profile-selection__percentage percentage-blob percentage-blob--red">
                        <div class="percentage-blob__value">{{getSegmentPercentage('enthusiast')}}</div>
                        <div class="percentage-blob__symbol">%</div>
                        <div class="percentage-blob__text" :class="{'percentage-blob__text--right-align': ($route.query.lang === 'en')}" v-html="populationString"></div>
                    </div>
                </div>
            </template>

            <template v-if="selectedProfile === 'easyHealth'">
                <div class="profile-selection__segment-heading" v-html="getSegmentHeading('easyHealth')"></div>
                <div class="profile-selection__segment-text" v-html="getSegmentText('easyHealth')"></div>
                <div class="profile-selection__footer">
                    <div class="profile-selection__segment-stats">
                        <div class="profile-selection__segment-stats-heading" v-html="s_('profilesPopup.demographicHeading')"></div>
                        <div class="profile-selection__segment-stats-text" v-html="getSegmentDemographicText('easyHealth')"></div>
                    </div>
                    <div class="profile-selection__segment-button" @click="goToPathByID(easyHealthLinkEn)" v-html="s_('profileSelection.meetEasyHealth')"></div>
                    <div class="profile-selection__percentage percentage-blob percentage-blob--blue">
                        <div class="percentage-blob__value">{{getSegmentPercentage('easyHealth')}}</div>
                        <div class="percentage-blob__symbol">%</div>
                        <div class="percentage-blob__text" :class="{'percentage-blob__text--right-align': ($route.query.lang === 'en')}" v-html="populationString"></div>
                    </div>
                </div>
            </template>

            <template v-if="selectedProfile === 'healthyFeelGoods'">
                <div class="profile-selection__segment-heading" v-html="getSegmentHeading('healthyFeelGoods')"></div>
                <div class="profile-selection__segment-text" v-html="getSegmentText('healthyFeelGoods')"></div>
                <div class="profile-selection__footer">
                    <div class="profile-selection__segment-stats">
                        <div class="profile-selection__segment-stats-heading" v-html="s_('profilesPopup.demographicHeading')"></div>
                        <div class="profile-selection__segment-stats-text" v-html="getSegmentDemographicText('healthyFeelGoods')"></div>
                    </div>
                    <div class="profile-selection__segment-button" @click="goToPathByID(healthyFeelGoodsLinkEn)" v-html="s_('profileSelection.meetHealthyFeelGoods')"></div>
                    <div class="profile-selection__percentage percentage-blob percentage-blob--yellow">
                        <div class="percentage-blob__value">{{getSegmentPercentage('healthyFeelGoods')}}</div>
                        <div class="percentage-blob__symbol">%</div>
                        <div class="percentage-blob__text" :class="{'percentage-blob__text--right-align': ($route.query.lang === 'en')}" v-html="populationString"></div>
                    </div>
                </div>
            </template>

        </div>

    </div>

</template>

<script>
import HotspotButton from '@/components/scene-components/HotspotButton';
import {goToPathByID, getUrlInfo} from '@/helpers/navigationHelper';
import * as languageHelper from '@/helpers/languageHelper';

export default {
  name: 'ProfileSelection',
  components: {HotspotButton},
  props:
  {
      // NOTE: English
    theEnthusiastsLinkEn: Number,
    healthyFeelGoodsLinkEn: Number,
    easyHealthLinkEn: Number,

    // NOTE: Chinese
    theEnthusiastLinkZh: Number,
    healthyFeelGoodsLinkZh: Number,
    easyHealthLinkZh: Number,

    // NOTE: Portguese
    theEnthusiastLinkPt: Number,
    healthyFeelGoodsLinkPt: Number,
    easyHealthLinkPt: Number,

    whyDataMattersLink: Number,
    methodologyLink: Number,
  },
  data()
  {
    return {
        segmentDescriptions: {}
    }
  },
  computed:
  {
    selectedProfile()
    {
        return this.$route.query.selectedProfile;
    },
    populationString()
    {
      var region = getUrlInfo().region;
      if (!region) region = 'general';

      var result = '';

      if (region === 'general')
      {
        result = this.s_('profilesPopup.ofTheGlobalPop');
      }
      else if (region === 'uk')
      {
        result = this.s_('profilesPopup.ofTheUkPop');
      }
      else if (region === 'usa')
      {
        result = this.s_('profilesPopup.ofTheUsPop');
      }
      else if (region === 'china')
      {
        result = this.s_('profilesPopup.ofTheChinesePop');
      }
      else if (region === 'japan')
      {
        result = this.s_('profilesPopup.ofTheJapanesePop');
      }
      else if (region === 'brazil')
      {
        result = this.s_('profilesPopup.ofTheBrazilianPop');
      }

      return result;
    },
  },
  created()
  {
    this.segmentDescriptions = this.$store.state.segmentDescriptions;
  },
  methods:
  {
    s_(stringId)
    {
        return languageHelper.getSystemString(stringId);
    },
    goToPathByID(id)
    {
        goToPathByID(id);
    },
    selectProfile(profileId)
    {
        /* eslint-disable no-unused-vars */
        var { selectedProfile, ...queryMinusSelectedProfile } = this.$route.query;
        /* eslint-enable no-unused-vars */

        // NOTE: Close if already open
        if (profileId === this.selectedProfile)
        {
            // NOTE: Make sure to retain all other params here
            
            this.$router.replace({ path: this.$route.path, query: queryMinusSelectedProfile});
        }
        // NOTE: Open
        else {
            this.$router.replace({ path: this.$route.path, query: { selectedProfile: profileId, ...queryMinusSelectedProfile}});
        }
    },
    getTranslatedProp(propEn, propZh, propPt)
    {
      var currentLangauge = languageHelper.getLanguage();

      if (currentLangauge === 'zh')
      {
        return propZh;
      }
      else if (currentLangauge === 'pt')
      {
        return propPt;
      }

      return propEn;
    },
    getSegmentHeading(segmentName)
    {
      var value = '';

      if (segmentName === 'enthusiast') {value = this.getTranslatedProp(this.segmentDescriptions.theEnthusiastHeading, this.segmentDescriptions.theEnthusiastHeadingZh, this.segmentDescriptions.theEnthusiastHeadingPt)}
      else if (segmentName === 'easyHealth') {value = this.getTranslatedProp(this.segmentDescriptions.easyHealthHeading,this.segmentDescriptions.easyHealthHeadingZh, this.segmentDescriptions.easyHealthHeadingPt)}
      else if (segmentName === 'healthyFeelGoods') {value = this.getTranslatedProp(this.segmentDescriptions.healthyFeelGoodsHeading, this.segmentDescriptions.healthyFeelGoodsHeadingZh, this.segmentDescriptions.healthyFeelGoodsHeadingPt)}

      return value;
    },
    getSegmentText(segmentName)
    {
      var value = '';

      var region = getUrlInfo().region;
      if (!region) region = 'general';

      if (region === 'general')
      {
        if (segmentName === 'enthusiast') {value = this.segmentDescriptions.generalTheEnthusiastText}
        else if (segmentName === 'easyHealth') {value = this.segmentDescriptions.generalEasyHealthText}
        else if (segmentName === 'healthyFeelGoods') {value = this.segmentDescriptions.generalHealthyFeelGoodsText}
      }
      else if (region === 'uk')
      {
        if (segmentName === 'enthusiast') {value = this.segmentDescriptions.ukTheEnthusiastText}
        else if (segmentName === 'easyHealth') {value = this.segmentDescriptions.ukEasyHealthText}
        else if (segmentName === 'healthyFeelGoods') {value = this.segmentDescriptions.ukHealthyFeelGoodsText}
      }
      else if (region === 'usa')
      {
        if (segmentName === 'enthusiast') {value = this.segmentDescriptions.usaTheEnthusiastText}
        else if (segmentName === 'easyHealth') {value = this.segmentDescriptions.usaEasyHealthText}
        else if (segmentName === 'healthyFeelGoods') {value = this.segmentDescriptions.usaHealthyFeelGoodsText}
      }
      else if (region === 'china')
      {
        if (segmentName === 'enthusiast') {value = this.getTranslatedProp(this.segmentDescriptions.chinaTheEnthusiastText, this.segmentDescriptions.chinaTheEnthusiastTextZh, this.segmentDescriptions.chinaTheEnthusiastText)}
        else if (segmentName === 'easyHealth') {value = this.getTranslatedProp(this.segmentDescriptions.chinaEasyHealthText, this.segmentDescriptions.chinaEasyHealthTextZh, this.segmentDescriptions.chinaEasyHealthText)}
        else if (segmentName === 'healthyFeelGoods') {value = this.getTranslatedProp(this.segmentDescriptions.chinaHealthyFeelGoodsText, this.segmentDescriptions.chinaHealthyFeelGoodsTextZh, this.segmentDescriptions.chinaHealthyFeelGoodsText)}
      }
      else if (region === 'japan')
      {
        if (segmentName === 'enthusiast') {value = this.segmentDescriptions.japanTheEnthusiastText}
        else if (segmentName === 'easyHealth') {value = this.segmentDescriptions.japanEasyHealthText}
        else if (segmentName === 'healthyFeelGoods') {value = this.segmentDescriptions.japanHealthyFeelGoodsText}
      }
      else if (region === 'brazil')
      {
        if (segmentName === 'enthusiast') {value = this.getTranslatedProp(this.segmentDescriptions.brazilTheEnthusiastText, this.segmentDescriptions.brazilTheEnthusiastText, this.segmentDescriptions.brazilTheEnthusiastTextPt)}
        else if (segmentName === 'easyHealth') {value = this.getTranslatedProp(this.segmentDescriptions.brazilEasyHealthText, this.segmentDescriptions.brazilEasyHealthText, this.segmentDescriptions.brazilEasyHealthTextPt)}
        else if (segmentName === 'healthyFeelGoods') {value = this.getTranslatedProp(this.segmentDescriptions.brazilHealthyFeelGoodsText, this.segmentDescriptions.brazilHealthyFeelGoodsText, this.segmentDescriptions.brazilHealthyFeelGoodsTextPt)}
      }
    
      return value;
    },
    getSegmentDemographicText(segmentName)
    {
      var value = '';

      var region = getUrlInfo().region;
      if (!region) region = 'general';

      if (region === 'general')
      {
        if (segmentName === 'enthusiast') {value = this.segmentDescriptions.generalTheEnthusiastDemographics}
        else if (segmentName === 'easyHealth') {value = this.segmentDescriptions.generalEasyHealthDemographics}
        else if (segmentName === 'healthyFeelGoods') {value = this.segmentDescriptions.generalHealthyFeelGoodsDemographics}
      }
      else if (region === 'uk')
      {
        if (segmentName === 'enthusiast') {value = this.segmentDescriptions.ukTheEnthusiastDemographics}
        else if (segmentName === 'easyHealth') {value = this.segmentDescriptions.ukEasyHealthDemographics}
        else if (segmentName === 'healthyFeelGoods') {value = this.segmentDescriptions.ukHealthyFeelGoodsDemographics}
      }
      else if (region === 'usa')
      {
        if (segmentName === 'enthusiast') {value = this.segmentDescriptions.usaTheEnthusiastDemographics}
        else if (segmentName === 'easyHealth') {value = this.segmentDescriptions.usaEasyHealthDemographics}
        else if (segmentName === 'healthyFeelGoods') {value = this.segmentDescriptions.usaHealthyFeelGoodsDemographics}
      }
      else if (region === 'china')
      {
        if (segmentName === 'enthusiast') {value = this.getTranslatedProp(this.segmentDescriptions.chinaTheEnthusiastDemographics, this.segmentDescriptions.chinaTheEnthusiastDemographicsZh, this.segmentDescriptions.chinaTheEnthusiastDemographics)}
        else if (segmentName === 'easyHealth') {value = this.getTranslatedProp(this.segmentDescriptions.chinaEasyHealthDemographics, this.segmentDescriptions.chinaEasyHealthDemographicsZh, this.segmentDescriptions.chinaEasyHealthDemographics)}
        else if (segmentName === 'healthyFeelGoods') {value = this.getTranslatedProp(this.segmentDescriptions.chinaHealthyFeelGoodsDemographics, this.segmentDescriptions.chinaHealthyFeelGoodsDemographicsZh, this.segmentDescriptions.chinaHealthyFeelGoodsDemographics)}
      }
      else if (region === 'japan')
      {
        if (segmentName === 'enthusiast') {value = this.segmentDescriptions.japanTheEnthusiastDemographics}
        else if (segmentName === 'easyHealth') {value = this.segmentDescriptions.japanEasyHealthDemographics}
        else if (segmentName === 'healthyFeelGoods') {value = this.segmentDescriptions.japanHealthyFeelGoodsDemographics}
      }
      else if (region === 'brazil')
      {
        if (segmentName === 'enthusiast') {value = this.getTranslatedProp(this.segmentDescriptions.brazilTheEnthusiastDemographics, this.segmentDescriptions.brazilTheEnthusiastDemographics, this.segmentDescriptions.brazilTheEnthusiastDemographicsPt)}
        else if (segmentName === 'easyHealth') {value = this.getTranslatedProp(this.segmentDescriptions.brazilEasyHealthDemographics, this.segmentDescriptions.brazilEasyHealthDemographics, this.segmentDescriptions.brazilEasyHealthDemographicsPt)}
        else if (segmentName === 'healthyFeelGoods') {value = this.getTranslatedProp(this.segmentDescriptions.brazilHealthyFeelGoodsDemographics, this.segmentDescriptions.brazilHealthyFeelGoodsDemographics, this.segmentDescriptions.brazilHealthyFeelGoodsDemographicsPt)}
      }

      return value;
    },
    getSegmentPercentage(segmentName)
    {
      var value = '';

      var region = getUrlInfo().region;
      if (!region) region = 'general';

      if (region === 'general')
      {
        if (segmentName === 'enthusiast') {value = this.segmentDescriptions.generalTheEnthusiastPercentage}
        else if (segmentName === 'easyHealth') {value = this.segmentDescriptions.generalEasyHealthPercentage}
        else if (segmentName === 'healthyFeelGoods') {value = this.segmentDescriptions.generalHealthyFeelGoodsPercentage}
      }
      else if (region === 'uk')
      {
        if (segmentName === 'enthusiast') {value = this.segmentDescriptions.ukTheEnthusiastPercentage}
        else if (segmentName === 'easyHealth') {value = this.segmentDescriptions.ukEasyHealthPercentage}
        else if (segmentName === 'healthyFeelGoods') {value = this.segmentDescriptions.ukHealthyFeelGoodsPercentage}
      }
      else if (region === 'usa')
      {
        if (segmentName === 'enthusiast') {value = this.segmentDescriptions.usaTheEnthusiastPercentage}
        else if (segmentName === 'easyHealth') {value = this.segmentDescriptions.usaEasyHealthPercentage}
        else if (segmentName === 'healthyFeelGoods') {value = this.segmentDescriptions.usaHealthyFeelGoodsPercentage}
      }
      else if (region === 'china')
      {
        if (segmentName === 'enthusiast') {value = this.segmentDescriptions.chinaTheEnthusiastPercentage}
        else if (segmentName === 'easyHealth') {value = this.segmentDescriptions.chinaEasyHealthPercentage}
        else if (segmentName === 'healthyFeelGoods') {value = this.segmentDescriptions.chinaHealthyFeelGoodsPercentage}
      }
      else if (region === 'japan')
      {
        if (segmentName === 'enthusiast') {value = this.segmentDescriptions.japanTheEnthusiastPercentage}
        else if (segmentName === 'easyHealth') {value = this.segmentDescriptions.japanEasyHealthPercentage}
        else if (segmentName === 'healthyFeelGoods') {value = this.segmentDescriptions.japanHealthyFeelGoodsPercentage}
      }
      else if (region === 'brazil')
      {
        if (segmentName === 'enthusiast') {value = this.segmentDescriptions.brazilTheEnthusiastPercentage}
        else if (segmentName === 'easyHealth') {value = this.segmentDescriptions.brazilEasyHealthPercentage}
        else if (segmentName === 'healthyFeelGoods') {value = this.segmentDescriptions.brazilHealthyFeelGoodsPercentage}
      }

      return value;
    }
  }
}
</script>
