<template>

  <MainNavigation v-if="sceneContent.sceneData"/>

  <div class="scene-view-offset" :style="sceneViewOffsetStyle">

    <transition name="fade" mode="out-in" @after-leave="afterTransition">
      <Scene
        :style="'transform-origin: '+transitionZoomTransformOrigin"
        :class="[{'scene--transition-effect-fade': (transitionType === 'fade')}, {'scene--transition-effect-zoom': (transitionType === 'zoomAndFade')}]"
        v-if="sceneContent.sceneData"
        :key="sceneContent.sceneData.id"
        :background-image="sceneContent.sceneData.data.backgroundImage"
        :background-video="sceneContent.sceneData.data.backgroundVideo"
        :videoToggleButtonStyle="sceneContent.sceneData.data.videoToggleButtonStyle">

        <!-- NOTE: Child views are rendered here if any -->
        <router-view></router-view>

        <SceneComponentRenderer :sceneComponents="sceneContent.sceneData.sceneComponents"/>
      </Scene>
    </transition>


    <ContentComponentRenderer :contentComponentData="sceneContent.contentComponentData" :currentSceneId="currentSceneId"/>

  </div>

</template>

<script>
// @ is an alias to /src
import Scene from '@/components/Scene';
import SceneComponentRenderer from '@/components/SceneComponentRenderer';
import ContentComponentRenderer from '@/components/ContentComponentRenderer';
import {getSceneContentByPath} from '@/helpers/navigationHelper';
import MainNavigation from '@/components/MainNavigation';

export default {
  name: 'SceneView',
  components: {
    Scene: Scene,
    SceneComponentRenderer: SceneComponentRenderer,
    ContentComponentRenderer: ContentComponentRenderer,
    MainNavigation: MainNavigation
  },
  data()
  {
    return {
      sceneContent: {},
      transitionType: 'fade',
      transitionZoomTransformOrigin: '50% 50%',
      sceneViewOffsetStyle: {transform: 'translate(0,0)'},
      currentSceneId: null
    }
  },
  beforeRouteUpdate(to, from, next)
  {
    // NOTE: Get scene data on update
    var sceneContent = getSceneContentByPath(to.path);
    this.currentSceneId = sceneContent.sceneData.id;


    this.$nextTick(()=>{
      this.sceneContent = sceneContent;
      next();
    });

  },
  created()
  {
    if (!this.$store.state.isLoading)
    {
      this.sceneContent = getSceneContentByPath(this.$route.path);
    }

    // NOTE: Get initial scene data
    this.unsubscribe = this.$store.subscribe((mutation, state) =>
    {
      // NOTE: React to the manifest being ready for use
      if (mutation.type === 'attatchSortedManifestData')
      {
        this.sceneContent = getSceneContentByPath(this.$route.path);
      }

      // NOTE: React to transitions
      else if (mutation.type === 'setSceneTransitionEffect')
      {
        this.transitionType = state.sceneTransitionEffect.type;
        if (state.sceneTransitionEffect.type === 'zoomAndFade')
        {
          this.transitionZoomTransformOrigin = state.sceneTransitionEffect.transformOrigin;
        }
      }

      else if (mutation.type === 'setSceneViewOffset')
      {
        this.sceneViewOffsetStyle = {
          transform: 'translate('+state.sceneViewOffset.x+'%,'+state.sceneViewOffset.y+'%)',
          transition: state.sceneViewOffset.transition+'ms'
        }
      }
    })
  },
  methods: {
    afterTransition()
    {
      // NOTE: Reset transition effect
      this.$store.commit('setSceneTransitionEffect', {type: 'fade'});
    },
  },
  unmounted()
  {
    this.unsubscribe();
  }
}
</script>