import config from '@/config';

var loadedImages = []; // NOTE: Contains images identifiable by URL {url: String, type: String, image: Canvas or Image element}

function preloadImage(url, useDecodeCanvas)
{
    return new Promise((resolve, reject) => {

        const image = new Image();
        image.addEventListener('error', ()=>{
            reject();
        });
        image.addEventListener('load', () => {
            if (useDecodeCanvas)
            {
                // NOTE: Creates a canvas and renders the image onto that, and returns the canvas instead of Image.
                // This ensures that we decode and retain the decoded image.
                // Do not use if loading a lot of images. It can quickly use up a lot of memory, but shaves off a few milliseconds when rendering.

                var decodeCanvas = document.createElement('canvas');
                var dectodeCtx = decodeCanvas.getContext('2d');
                decodeCanvas.width = image.width;
                decodeCanvas.height = image.height;
                dectodeCtx.drawImage(image, 0, 0);

                loadedImages.push({
                    url: url,
                    type: 'decodedCanvas',
                    image: decodeCanvas
                });
                resolve(decodeCanvas);
            }
            else 
            {
                loadedImages.push({
                    url: url,
                    type: 'image',
                    image: image
                });
                resolve(image);
            }
        });
        image.src = url;
    });
}

function preloadImages(imageUrls, useDecodeCanvas)
{
    var imageLoadingPromises = [];

    imageUrls.forEach((imageUrl)=>{
        var preloadPromise = preloadImage(imageUrl, useDecodeCanvas)
            .catch(()=>{
                console.log('Failed to load asset:', imageUrl);
            });
        imageLoadingPromises.push(preloadPromise);
    });

    return Promise.all(imageLoadingPromises)
        .then(()=>{
            // console.log("IMAGES PRELOADED", loadedImages);
        })
}

function getPreloadedImage(url)
{
    var result = null;

    loadedImages.forEach((loadedImage)=>{
        if (url === loadedImage.url)
        {
            result = loadedImage.image;
        }
    })

    return result;
}

function getUmbracoMediaItemSrc(umbracoMedia)
{
    var url = config.umbracoMediaPath + umbracoMedia.src;
    return url;
}

export {
    preloadImage,
    preloadImages,
    getPreloadedImage,
    getUmbracoMediaItemSrc
};
