function setCookie(cname, cvalue, exdays)
{
  if (!hasConsent()) return;
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires="+d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function deleteCookie(cname)
{
  setCookie(cname, null, 0); // NOTE: Delete the cookie by setting the value to expire immediately.
}

function hasConsent()
{
  /*global Cookiebot */

  if (Cookiebot && Cookiebot.consent.necessary)
  {
    return true;
  }

  return false;
}

export {
    setCookie,
    getCookie,
    deleteCookie
}