<template>

    <Scene
    :background-image="sceneImage"
    :background-video="scenevideo"
    :videoToggleButtonStyle="'hidden'">

        <div class="logo"></div>

        <div class="login-screen">

            <div class="language-selector login-screen__langauge-selector">
                <div class="login-screen__heading language-selector__heading">{{s_('login.chooseLanguage')}}</div>

                <div class="language-selector__list-wrapper">
                    <div class="language-selector__list">
                        <div class="language-selector__language language-selector__language--en" :class="{'language-selector__language--current': currentLanguage === 'en'}" @click="setLanguage('en')"></div>
                        <div class="language-selector__language language-selector__language--zh" :class="{'language-selector__language--current': currentLanguage === 'zh'}" @click="setLanguage('zh')"></div>
                        <div class="language-selector__language language-selector__language--pt" :class="{'language-selector__language--current': currentLanguage === 'pt'}" @click="setLanguage('pt')"></div>
                    </div>
                </div>

                <div class="login-screen__heading language-selector__region-heading">{{s_('login.chooseMarket')}}</div>
                <div class="language-selector__region-list-wrapper">
                    <div class="language-selector__region-list">
                        <template v-if="currentLanguage === 'en'">
                            <div class="language-selector__region language-selector__region--general" :class="{'language-selector__region--current': selectedRegion === 'general'}" @click="selectedRegion = 'general'"></div>
                            <div class="language-selector__region language-selector__region--uk" :class="{'language-selector__region--current': selectedRegion === 'uk'}" @click="selectedRegion = 'uk'"></div>
                            <div class="language-selector__region language-selector__region--usa" :class="{'language-selector__region--current': selectedRegion === 'usa'}" @click="selectedRegion = 'usa'"></div>
                            <div class="language-selector__region language-selector__region--china" :class="{'language-selector__region--current': selectedRegion === 'china'}" @click="selectedRegion = 'china'"></div>
                            <div class="language-selector__region language-selector__region--japan" :class="{'language-selector__region--current': selectedRegion === 'japan'}" @click="selectedRegion = 'japan'"></div>
                            <div class="language-selector__region language-selector__region--brazil" :class="{'language-selector__region--current': selectedRegion === 'brazil'}" @click="selectedRegion = 'brazil'"></div>
                        </template>
                        <template v-if="currentLanguage === 'zh'">
                            <div class="language-selector__region language-selector__region--china" :class="{'language-selector__region--current': selectedRegion === 'china'}" @click="selectedRegion = 'china'"></div>
                        </template>
                        <template v-if="currentLanguage === 'pt'">
                            <div class="language-selector__region language-selector__region--brazil" :class="{'language-selector__region--current': selectedRegion === 'brazil'}" @click="selectedRegion = 'brazil'"></div>
                        </template>
                    </div>
                </div>

            </div>

            <form @submit.prevent="login" class="login-screen__form">
                <div class="login-screen__heading">{{s_('login.login')}}</div>
                <div class="login-screen__fields">
                    <input class="login-screen__input" type="password" @keydown="showValidationError = false" v-model="passwordInput" autocomplete="off" :placeholder="s_('login.password')"/>
                    <input type="submit" class="login-screen__submit" value=" "/>
                </div>
                <div class="login-screen__error-message" v-if="showValidationError">
                    <div class="login-screen__error-heading">{{s_('login.wrongPassword')}}</div>
                    <div class="login-screen__error-text">{{s_('login.pleaseTryAgain')}}</div>
                </div>
            </form>

        </div>

    </Scene>

</template>

<script>
import Scene from '@/components/Scene';
import {goToPath} from '@/helpers/navigationHelper';
import * as languageHelper from '@/helpers/languageHelper';

export default {
  name: 'LoginView',
  components: {Scene},
  data()
  {
      return {
          passwordString: this.$store.state.siteInfo.sitePassword,
          sceneImage: this.$store.state.siteInfo.loginBackgroundImage,
          scenevideo: this.$store.state.siteInfo.loginBackgroundVideo,
          passwordInput: '',
          showValidationError: false,
          selectedRegion: 'general',
      }
  },
  computed:
  {
    currentLanguage()
    {
        return languageHelper.getLanguage();
    }
  },
  created()
  {
    this.unsubscribe = this.$store.subscribe((mutation, state) =>
    {
      // NOTE: React to the manifest being ready for use
      if (mutation.type === 'attatchSortedManifestData')
      {
          this.passwordString = state.siteInfo.sitePassword;
          this.sceneImage = state.siteInfo.loginBackgroundImage;
          this.scenevideo = state.siteInfo.loginBackgroundVideo;
      }
    });
  },
  unmounted()
  {
    this.unsubscribe();
  },
  methods:
  {
    s_(stringId)
    {
      return languageHelper.getSystemString(stringId);
    },
    login()
    {
        if (this.passwordInput === this.passwordString)
        {
            this.$store.commit('login', {lang: this.$route.query.lang});
            var loginRedirect = (this.$route.query.loginRedirect) ? (this.$route.query.loginRedirect) : '/'+this.selectedRegion+'/';
            goToPath(loginRedirect);
        }
        else
        {
            this.showValidationError = true;
        }
    },
    setLanguage(languageiD)
    {
        // NOTE: Set default region
        if (languageiD === 'zh')
        {
            this.selectedRegion = 'china';
        }
        else if (languageiD === 'pt')
        {
            this.selectedRegion = 'brazil';
        }
        else
        {
            this.selectedRegion = 'general';
        }

        languageHelper.setLanguage(languageiD);
    },
  }
}
</script>