<template>
  <div class="deep-data" :class="['deep-data--color-'+color, 'deep-data--language-'+$route.query.lang]" ref="container">

    <ScrollBar @scroll="onScroll">

      <div class="deep-data__inner-wrap">

        <div class="deep-data__close-btn" @click="$emit('close')"></div>

        <div class="deep-data__header" :class="[{'deep-data__header--undocked': headerIsUndocked}, {'deep-data__header--with-sub-header': showSubHeader()}]">
          
          <div class="deep-data__heading" v-html="s_('deepData.heading')"></div>

          <div class="deep-data__header-table-wrap">
            <table class="deep-data__table deep-data__table--show-hide-table">
                <tr class="deep-data__show-hide-header">
                  <th class="deep-data__col-hide-row"></th> 
                  <th class="deep-data__col-text">{{s_('deepData.showHide')}}</th>
                  <th class="deep-data__col-total deep-data__col-hide"><HideToggle :isToggled="!isHidden('total')" @click="toggleHide('total')"/></th>
                  <th class="deep-data__col-all-other deep-data__col-hide"><HideToggle :isToggled="!isHidden('all-other')" @click="toggleHide('all-other')"/></th>
                  <th class="deep-data__col-enthusiast deep-data__col-hide"><HideToggle :isToggled="!isHidden('enthusiast')" @click="toggleHide('enthusiast')"/></th>
                  <th class="deep-data__col-easy-health deep-data__col-hide"><HideToggle :isToggled="!isHidden('easy-health')" @click="toggleHide('easy-health')"/></th>
                  <th class="deep-data__col-healthy-feel-goods deep-data__col-hide"><HideToggle :isToggled="!isHidden('healthy-feel-goods')" @click="toggleHide('healthy-feel-goods')"/></th>
                  <th class="deep-data__col-space"></th>
                  <th class="deep-data__col-brazil deep-data__col-hide"><HideToggle :isToggled="!isHidden('brazil')" @click="toggleHide('brazil')"/></th>
                  <th class="deep-data__col-china deep-data__col-hide"><HideToggle :isToggled="!isHidden('china')" @click="toggleHide('china')"/></th>
                  <th class="deep-data__col-japan deep-data__col-hide"><HideToggle :isToggled="!isHidden('japan')" @click="toggleHide('japan')"/></th>
                  <th class="deep-data__col-uk deep-data__col-hide"><HideToggle :isToggled="!isHidden('uk')" @click="toggleHide('uk')"/></th>
                  <th class="deep-data__col-usa deep-data__col-hide"><HideToggle :isToggled="!isHidden('usa')" @click="toggleHide('usa')"/></th>
                </tr>
            </table>
          </div>

          <div class="deep-data__sub-table-header">

            <div class="deep-data__table-wrap">
              <table class="deep-data__table deep-data__table--content-table">
                <tr>
                  <th class="deep-data__col-hide-row"></th>
                  <th class="deep-data__col-compare"></th>
                  <th class="deep-data__col-text"></th>
                  <th class="deep-data__col-total" v-html="s_('deepData.headerTotal')"></th>
                  <th class="deep-data__col-all-other" v-html="s_('deepData.headerAllOther')"></th>
                  <th class="deep-data__col-enthusiast"><div class="profile-icon profile-icon--red"><span v-html="s_('deepData.headerEnthusiast')"></span></div></th>
                  <th class="deep-data__col-easy-health"><div class="profile-icon profile-icon--blue"><span v-html="s_('deepData.headerEasyHealth')"></span></div></th>
                  <th class="deep-data__col-healthy-feel-goods"><div class="profile-icon profile-icon--yellow"><span v-html="s_('deepData.headerHealthyFeelGoods')"></span></div></th>
                  <th class="deep-data__col-space"></th>
                  <th class="deep-data__col-brazil" v-html="s_('deepData.headerBrazil')"></th>
                  <th class="deep-data__col-china" v-html="s_('deepData.headerChina')"></th>
                  <th class="deep-data__col-japan" v-html="s_('deepData.headerJapan')"></th>
                  <th class="deep-data__col-uk" v-html="s_('deepData.headerUk')"></th>
                  <th class="deep-data__col-usa" v-html="s_('deepData.headerUs')"></th>
                </tr>
              </table>
            </div>

          </div>

        </div>

        <div class="deep-data-elements">

          <div class="deep-data__element" v-for="(table, i) in tableData" :key="i" ref="elements" :data-id="table.tableId">
            
            <div class="deep-data__info">
              <div class="deep-data__info-heading" v-html="getTranslatedProp(table.question, table.questionZh, table.questionPt)"></div>
              <div class="deep-data__info-text" v-html="getTranslatedProp(table.conclusionText, table.conclusionTextZh, table.conclusionTextPt)"></div>
              <div class="deep-data__info-Qid" v-html="table.questionId"></div>
            </div>

            <div class="deep-data__table-wrap">
            <table class="deep-data__table deep-data__table--content-table">
              <tr>
                <th class="deep-data__col-hide-row"></th>
                <th class="deep-data__col-compare"></th> 
                <th class="deep-data__col-text"></th>
                <th class="deep-data__col-total" v-html="s_('deepData.headerTotal')"></th>
                <th class="deep-data__col-all-other" v-html="s_('deepData.headerAllOther')"></th>
                <th class="deep-data__col-enthusiast"><div class="profile-icon profile-icon--red"><span v-html="s_('deepData.headerEnthusiast')"></span></div></th>
                <th class="deep-data__col-easy-health"><div class="profile-icon profile-icon--blue"><span v-html="s_('deepData.headerEasyHealth')"></span></div></th>
                <th class="deep-data__col-healthy-feel-goods"><div class="profile-icon profile-icon--yellow"><span v-html="s_('deepData.headerHealthyFeelGoods')"></span></div></th>
                <th class="deep-data__col-space"></th>
                <th class="deep-data__col-brazil" v-html="s_('deepData.headerBrazil')"></th>
                <th class="deep-data__col-china" v-html="s_('deepData.headerChina')"></th>
                <th class="deep-data__col-japan" v-html="s_('deepData.headerJapan')"></th>
                <th class="deep-data__col-uk" v-html="s_('deepData.headerUk')"></th>
                <th class="deep-data__col-usa" v-html="s_('deepData.headerUs')"></th>
              </tr>



              <template v-for="(row,j) in table.data" :key="j">

          
                <tr v-if="!row.hideInDeepData" @click="toggleComparison(row, table.data)" @mouseleave="handleRowHoverLeave()" @mouseover="handleRowHoverOver(i, j)" :class="{'deep-data__hover-row': (hoverRowTableIndex === i && hoverRowRowIndex === j || row.openComparison)}" :key="j">
                
                  <td class="deep-data__col-hide-row"><HideToggle :isToggled="!isHidden('table-'+i+'row-'+j)" @click.stop="toggleHide('table-'+i+'row-'+j);"/></td>

                  <td class="deep-data__col-compare"  ><div v-if="showComparativeData(row)" class="comparing-arrows"></div></td> 

                  <td class="deep-data__col-text">{{getTranslatedProp(row.title, row.titleZh, row.titlePt)}}</td>
               

                  <template v-for="(field,k) in getRowsForRegion(row.currentFields)" :key="k">

                    <td class="deep-data__col-total" :class="{'deep-data__col-is-hidden': isHidden('total') || isHidden('table-'+i+'row-'+j)}">{{field.total}}<span v-if="field.total">%</span></td>
                    <td class="deep-data__col-all-other" :class="{'deep-data__col-is-hidden': isHidden('all-other') || isHidden('table-'+i+'row-'+j)}">{{field.allOthers}}<span v-if="field.allOthers && !table.isEarlyAdopterSegmentsStyleIndexTable">%</span></td>
                    <td class="deep-data__col-enthusiast" :class="{'deep-data__col-is-hidden': isHidden('enthusiast') || isHidden('table-'+i+'row-'+j)}">{{field.theEnthusiasts}}<span v-if="field.theEnthusiasts && !table.isEarlyAdopterSegmentsStyleIndexTable">%</span></td>
                    <td class="deep-data__col-easy-health" :class="{'deep-data__col-is-hidden': isHidden('easy-health') || isHidden('table-'+i+'row-'+j)}">{{field.easyHealth}}<span v-if="field.easyHealth && !table.isEarlyAdopterSegmentsStyleIndexTable">%</span></td>
                    <td class="deep-data__col-healthy-feel-goods" :class="{'deep-data__col-is-hidden': isHidden('healthy-feel-goods') || isHidden('table-'+i+'row-'+j)}">{{field.healthyFeelGoods}}<span v-if="field.healthyFeelGoods && !table.isEarlyAdopterSegmentsStyleIndexTable">%</span></td>
                    <td class="deep-data__col-space"></td>

                  </template>
                  


                  <!-- NOTE: Data from other regions (their totals) -->
                  <td class="deep-data__col-brazil" :class="{'deep-data__col-is-hidden': isHidden('brazil') || isHidden('table-'+i+'row-'+j)}" v-if="row.currentFields.find(({region})=>region === 'brazil')">{{row.currentFields.find(({region})=>region === 'brazil')[currentSegmentPropKey]}}%</td>
                  <td class="deep-data__col-brazil" :class="{'deep-data__col-is-hidden': isHidden('brazil') || isHidden('table-'+i+'row-'+j)}" v-else></td>

                  <td class="deep-data__col-china" :class="{'deep-data__col-is-hidden': isHidden('china') || isHidden('table-'+i+'row-'+j)}" v-if="row.currentFields.find(({region})=>region === 'china')">{{row.currentFields.find(({region})=>region === 'china')[currentSegmentPropKey]}}%</td>
                  <td class="deep-data__col-china" :class="{'deep-data__col-is-hidden': isHidden('china') || isHidden('table-'+i+'row-'+j)}" v-else></td>

                  <td class="deep-data__col-japan" :class="{'deep-data__col-is-hidden': isHidden('japan') || isHidden('table-'+i+'row-'+j)}" v-if="row.currentFields.find(({region})=>region === 'japan')">{{row.currentFields.find(({region})=>region === 'japan')[currentSegmentPropKey]}}%</td>
                  <td class="deep-data__col-japan" :class="{'deep-data__col-is-hidden': isHidden('japan') || isHidden('table-'+i+'row-'+j)}" v-else></td>
                  
                  <td class="deep-data__col-uk" :class="{'deep-data__col-is-hidden': isHidden('uk') || isHidden('table-'+i+'row-'+j)}" v-if="row.currentFields.find(({region})=>region === 'uk')">{{row.currentFields.find(({region})=>region === 'uk')[currentSegmentPropKey]}}%</td>
                  <td class="deep-data__col-uk" :class="{'deep-data__col-is-hidden': isHidden('uk') || isHidden('table-'+i+'row-'+j)}" v-else></td>
                  
                  <td class="deep-data__col-usa" :class="{'deep-data__col-is-hidden': isHidden('usa') || isHidden('table-'+i+'row-'+j)}" v-if="row.currentFields.find(({region})=>region === 'usa')">{{row.currentFields.find(({region})=>region === 'usa')[currentSegmentPropKey]}}%</td>
                  <td class="deep-data__col-usa" :class="{'deep-data__col-is-hidden': isHidden('usa') || isHidden('table-'+i+'row-'+j)}" v-else></td>

                </tr>


                <!-- old numbers (for optional comparison) -->

                <tr class="olddata" v-show="row.openComparison" v-if="!row.hideInDeepData">

                  <!-- show empty tr even if no comparison - to make odd-even css work properly -->

                  <template v-if="showComparativeData(row)">
              
                    <td class="deep-data__col-hide-row"></td>

                    <td class="deep-data__col-compare"></td> 

                    <td class="deep-data__col-text">{{getTranslatedProp(row.title, row.titleZh, row.titlePt)}} ({{ oldDataYear }})</td> 


                    <template v-for="(field,k) in getRowsForRegion(row.oldFields)" :key="k">

                      <td class="deep-data__col-total" :class="{'deep-data__col-is-hidden': isHidden('total') || isHidden('table-'+i+'row-'+j)}">{{field.total}}<span v-if="field.total">%</span></td>
                      <td class="deep-data__col-all-other" :class="{'deep-data__col-is-hidden': isHidden('all-other') || isHidden('table-'+i+'row-'+j)}">{{field.allOthers}}<span v-if="field.allOthers && !table.isEarlyAdopterSegmentsStyleIndexTable">%</span></td>
                      <td class="deep-data__col-enthusiast" :class="{'deep-data__col-is-hidden': isHidden('enthusiast') || isHidden('table-'+i+'row-'+j)}">{{field.theEnthusiasts}}<span v-if="field.theEnthusiasts && !table.isEarlyAdopterSegmentsStyleIndexTable">%</span></td>
                      <td class="deep-data__col-easy-health" :class="{'deep-data__col-is-hidden': isHidden('easy-health') || isHidden('table-'+i+'row-'+j)}">{{field.easyHealth}}<span v-if="field.easyHealth && !table.isEarlyAdopterSegmentsStyleIndexTable">%</span></td>
                      <td class="deep-data__col-healthy-feel-goods" :class="{'deep-data__col-is-hidden': isHidden('healthy-feel-goods') || isHidden('table-'+i+'row-'+j)}">{{field.healthyFeelGoods}}<span v-if="field.healthyFeelGoods && !table.isEarlyAdopterSegmentsStyleIndexTable">%</span></td>
                      <td class="deep-data__col-space"></td>

                    </template>
                    


                    <!-- NOTE: Data from other regions (their totals) -->
                    <td class="deep-data__col-brazil" :class="{'deep-data__col-is-hidden': isHidden('brazil') || isHidden('table-'+i+'row-'+j)}" v-if="row.fields.find(({region})=>region === 'brazil')">{{row.fields.find(({region})=>region === 'brazil')[currentSegmentPropKey]}}%</td>
                    <td class="deep-data__col-brazil" :class="{'deep-data__col-is-hidden': isHidden('brazil') || isHidden('table-'+i+'row-'+j)}" v-else></td>

                    <td class="deep-data__col-china" :class="{'deep-data__col-is-hidden': isHidden('china') || isHidden('table-'+i+'row-'+j)}" v-if="row.fields.find(({region})=>region === 'china')">{{row.fields.find(({region})=>region === 'china')[currentSegmentPropKey]}}%</td>
                    <td class="deep-data__col-china" :class="{'deep-data__col-is-hidden': isHidden('china') || isHidden('table-'+i+'row-'+j)}" v-else></td>

                    <td class="deep-data__col-japan" :class="{'deep-data__col-is-hidden': isHidden('japan') || isHidden('table-'+i+'row-'+j)}" v-if="row.fields.find(({region})=>region === 'japan')">{{row.fields.find(({region})=>region === 'japan')[currentSegmentPropKey]}}%</td>
                    <td class="deep-data__col-japan" :class="{'deep-data__col-is-hidden': isHidden('japan') || isHidden('table-'+i+'row-'+j)}" v-else></td>
                    
                    <td class="deep-data__col-uk" :class="{'deep-data__col-is-hidden': isHidden('uk') || isHidden('table-'+i+'row-'+j)}" v-if="row.fields.find(({region})=>region === 'uk')">{{row.fields.find(({region})=>region === 'uk')[currentSegmentPropKey]}}%</td>
                    <td class="deep-data__col-uk" :class="{'deep-data__col-is-hidden': isHidden('uk') || isHidden('table-'+i+'row-'+j)}" v-else></td>
                    
                    <td class="deep-data__col-usa" :class="{'deep-data__col-is-hidden': isHidden('usa') || isHidden('table-'+i+'row-'+j)}" v-if="row.fields.find(({region})=>region === 'usa')">{{row.fields.find(({region})=>region === 'usa')[currentSegmentPropKey]}}%</td>
                    <td class="deep-data__col-usa" :class="{'deep-data__col-is-hidden': isHidden('usa') || isHidden('table-'+i+'row-'+j)}" v-else></td>

                  </template>

                </tr>

              </template>

            </table>
            </div>

          </div>
        </div>

      </div>

    </ScrollBar>

  </div>
</template>

<script>
import {getPathByID, getUrlInfo} from '@/helpers/navigationHelper';
import {getCurrentSceneContent} from '@/helpers/navigationHelper';
import ScrollBar from '@/components/ScrollBar';
import HideToggle from '@/components/HideToggle';
//import DeepDataRow from '@/components/content-components/DeepDataRow';
import eventBus from '@/helpers/eventBus';
import * as languageHelper from '@/helpers/languageHelper';

export default {
  name: 'DeepData',
  props: {
    currentSceneId: Number,
    dataFields: Array
  },
  components: {
    HideToggle,
    ScrollBar,
    // DeepDataRow
  },
  data()
  {
    return {
      currentTabIndex: 0,
      popupVideoData: null,
      initialSceneId: getCurrentSceneContent().sceneData.id,
      toggleStates: {},
      tableData: [],
      hoverRowTableIndex: -1,
      hoverRowRowIndex: -1,
      scrollPosition: 0, // NOTE: For calculating wether header is docked/undocked
      oldDataYear: 2020,
      comparingRow:{},
      rowCount:0
    }
  },
  computed:
  {
    headerIsUndocked()
    {
      return (this.scrollPosition > 240);
    },
    currentRegion()
    {
      return getUrlInfo().region;
    },
    color()
    {
      var color = 'grey';

      var profile = getUrlInfo().profile;

      if (profile === 'performance-nutrition') {color = 'red'}
      else if (profile === 'active-nutrition') {color = 'blue'}
      else if (profile === 'lifestyle-nutrition') {color = 'yellow'}

      return color;
    },
    currentSegmentPropKey()
    {
      var segmentPropKey = '';

      var profile = getUrlInfo().profile;

      if (profile === 'performance-nutrition') {segmentPropKey = 'theEnthusiasts'}
      else if (profile === 'active-nutrition') {segmentPropKey = 'easyHealth'}
      else if (profile === 'lifestyle-nutrition') {segmentPropKey = 'healthyFeelGoods'}

      return segmentPropKey;
    }
  },
  created()
  {
    eventBus.emit('mainNavigation.hide');

    this.$store.commit('setSceneViewOffset', {x: 0, y: -100, transition: 1500});

    this.dataFields.forEach((dataField)=>{
      this.$store.state.surveyData.forEach((questionData)=>{
          
        if (dataField.dataReference === questionData.id)
        {
            // indsæt comparison toggle på hvert svar
            questionData.data.fields.forEach(element => {
              element.openComparison = false;
              // hvilken data er ny og hvilken er gammel
              element.currentFields = element.fields2022;
              element.oldFields = element.fields
            });
          

          this.tableData.push({

            tableId: dataField.dataReference,
            conclusionText: dataField.conclusionText,
            conclusionTextZh: dataField.conclusionTextZh,
            conclusionTextPt: dataField.conclusionTextPt,
            questionId: questionData.data.questionId,
            question: questionData.data.question,
            questionZh: questionData.data.questionZh,
            questionPt: questionData.data.questionPt,
            data: questionData.data.fields,
          
           
            allowComparison:true,
            // test end
            isEarlyAdopterSegmentsStyleIndexTable: questionData.data.isEarlyAdopterSegmentsStyleIndexTable
          });

      
        }
      });
    });
  },
  beforeUnmount()
  {
    /*if (this.currentSceneId === this.initialSceneId)
    {*/
      this.$store.commit('setSceneViewOffset', {x: 0, y: 0, transition: 1500});
    /*}
    else
    {
      this.$store.commit('setSceneViewOffset', {x: 0, y: 0, transition: 1500});
    }*/

    var scrollContainer = this.$refs.container.querySelector('.simplebar-content-wrapper');
    scrollContainer.scrollTo({top: 0, behavior: 'smooth'});

    this.$store.commit('setDeepDataAnchorTabelId', null);

    eventBus.emit('mainNavigation.unHide');
  },
  mounted()
  {
    var anchorTableId = this.$store.state.deepDataAnchorTabelId;
    var scrollContainer = this.$refs.container.querySelector('.simplebar-content-wrapper');

    var scrollTarget = 0;
    this.$refs.elements.forEach((element)=>{
      if (parseInt(anchorTableId) === parseInt(element.getAttribute('data-id')))
      {
        scrollTarget = element.offsetTop -100;
      }
    });
    
    scrollContainer.scrollTo({top: scrollTarget/*, behavior: 'smooth'*/});
    
  },
  unmounted()
  {
    if (this.unsubscribe)
    {
      this.unsubscribe();
    }
  },
  methods:
  {
    showSubHeader()
    {
      // NOTE: Check if we are currently scrolling over a table and return true if so
      var result = false;

      if (this.$refs.elements)
      {
        this.$refs.elements.forEach((element)=>{
          if (this.scrollPosition >= element.offsetTop - 90 && this.scrollPosition <= element.offsetTop + element.querySelector('table').offsetHeight - 150)
          {
            result = true;
          }
        });
      }

      return result;
    },
    handleRowHoverLeave()
    {
      this.hoverRowTableIndex = -1;
      this.hoverRowRowIndex = -1;
    },
    handleRowHoverOver(i, j)
    {
      this.hoverRowTableIndex = i;
      this.hoverRowRowIndex = j;
    },
    onScroll(event)
    {
      this.scrollPosition = event.target.scrollTop;
    },
    s_(stringId)
    {
        return languageHelper.getSystemString(stringId);
    },
    getTranslatedProp(propEn, propZh, propPt)
    {
      var currentLangauge = languageHelper.getLanguage();
      
      if (currentLangauge === 'zh')
      {
        return propZh;
      }
      else if (currentLangauge === 'pt')
      {
        return propPt;
      }

      return propEn;
    },
    getRowsForRegion(rowFields)
    {
      var result = [];
      
      rowFields.forEach((field)=>{
        if (field.region === this.currentRegion)
        {
          result.push(field);
        }
      });

      if (!result.length)
      {
        result.push({});
      }

      return result;
    },
    isHidden(toggleHideId)
    {
      return (this.toggleStates[toggleHideId] && this.toggleStates[toggleHideId] === true)
    },
    toggleHide(toggleHideId)
    {
      if (this.toggleStates[toggleHideId])
      {
        this.toggleStates[toggleHideId] = !this.toggleStates[toggleHideId];
      }
      else
      {
        this.toggleStates[toggleHideId] = true;
      }
    },
    openVideo(videoData)
    {
      this.popupVideoData = videoData;
    },
    goToTab(tabIndex)
    {
      this.currentTabIndex = tabIndex;
    },
    getPathById(targetId)
    {
      return getPathByID(targetId);
    },
    showComparativeData(rowData){
      if(rowData.allowComparison)
        return(true);
    },
    toggleComparison(rowData){

      if (this.showComparativeData(rowData)){

        // luk tidligere åbnet
        if (this.comparingRow && rowData != this.comparingRow){
          this.comparingRow.openComparison = false;
        }
        // åbn ny
        rowData.openComparison = !rowData.openComparison;
        // husk ny
        this.comparingRow = rowData;
      }
    }
  }
}
</script>
