<template>

  <Popup type="content" @close="$emit('close')" :color="color">
      <div class="content-popup" :class="'content-popup--color-'+color">

        <div class="content-popup__tabs">

          <div class="content-popup__tab"
            v-for="(tab,i) in tabs" :key="i"
            :class="[{'content-popup__tab--selected': i === currentTabIndex}, {'content-popup__tab--no-media': !tab.slides.length}]">
            <div class="content-poup__slideshow" v-if="tab.slides && tab.slides.length">
              <Slider v-if="tab.slides" :slides="tab.slides" style-variant="default" @openVideo="openVideo"/>
            </div>
            <div class="content-popup__text-content" ref="container">
              <ScrollBar>
                <div class="content-popup__text-content-inner">
                  <div class="content-popup__heading" v-html="getTranslatedProp(tab.heading, tab.headingZh, tab.headingPt)"></div>
                  <div class="content-popup__rich-text" v-html="getTranslatedProp(tab.text, tab.textZh, tab.textPt)"></div>
                  <div class="content-popup__call-to-action">

                    <template v-for="(link, j) in tab.callToActionLinks" :key="j">

                      <Button v-if="link.sceneContentReference" class="content-popup__button" :router-link="getPathById(link.sceneContentReference)">{{link.text}}</Button>

                      <Button v-else-if="link.externalURL" class="content-popup__button" :href="link.externalURL">{{link.text}}</Button>

                    </template>

                  </div>
                </div>
              </ScrollBar>
            </div>
          </div>

        </div>

        <div class="content-popup__tab-navigation" v-if="(tabs.length > 1)">
          <div v-for="(tab,i) in tabs" :key="i"
            class="content-popup__tab-navigation-dot"
            :class="{'content-popup__tab-navigation-dot--selected': i === currentTabIndex}"
            @click="goToTab(i)">
          </div>
        </div>
        
      </div>
  </Popup>

</template>

<script>
import {getPathByID, getUrlInfo} from '@/helpers/navigationHelper';
import Popup from '@/components/Popup';
import Button from '@/components/Button';
import Slider from '@/components/Slider';
import ScrollBar from '@/components/ScrollBar';
import * as languageHelper from '@/helpers/languageHelper';

export default {
  name: 'ContentPopup',
  components: {Popup, Button, Slider, ScrollBar/*, BasicVideo*/},
  props: {
    tabs: Array
  },
  data()
  {
    return {
      currentTabIndex: 0,
      popupVideoData: null
    }
  },
  computed:
  {
    color()
    {
      var color = 'grey';

      var profile = getUrlInfo().profile;

      console.log(profile)

      if (profile === 'performance-nutrition') {color = 'red'}
      else if (profile === 'active-nutrition') {color = 'blue'}
      else if (profile === 'lifestyle-nutrition') {color = 'yellow'}

      return color;
    }
  },
  methods:
  {
    getTranslatedProp(propEn, propZh, propPt)
    {
      var currentLangauge = languageHelper.getLanguage();

      if (currentLangauge === 'zh')
      {
        return propZh;
      }
      else if (currentLangauge === 'pt')
      {
        return propPt;
      }

      return propEn;
    },
    openVideo(videoData)
    {
      this.popupVideoData = videoData;
    },
    goToTab(tabIndex)
    {
      this.currentTabIndex = tabIndex;
    },
    getPathById(targetId)
    {
      return getPathByID(targetId);
    }
  },
}
</script>
