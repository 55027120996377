<template>

  <Popup type="content-popup-2" @close="$emit('close')">
      <div class="content-popup-2">

        <div class="content-popup-2__heading" v-html="headingTranslated"></div>

        <div class="content-popup-2__slider">

            <div class="content-popup-2__slides" :style="'transform: translateX(-'+ currentSlideIndex*100 +'%)'">
            
                <div class="content-popup-2__slide" v-for="slide,i in slides" :key="i">


                    <div class="content-popup-2__column" v-for="column,j in slide.content" :key="j">

                        <div class="content-popup-2__content">

                            <div v-if="column.showArrowToNextColumn" class="content-popup-2__arrow-to-next-col"></div>

                            <div v-if="column.largeIcon" class="content-popup-2__large-icon" :class="'content-popup-2__large-icon--'+column.largeIcon"></div>

                            <div v-if="column.icon" class="content-popup-2__icon" :class="'content-popup-2__icon--'+column.icon"></div>

                            <div v-if="column.statValue || getTranslatedProp(column.statLabel, column.labelZh, column.labelPt)" class="content-popup-2__stat">
                                <div class="content-popup-2__stat-value" v-if="currentLang !== 'zh'">{{column.statValue}}</div>
                                <div class="content-popup-2__stat-label">{{getTranslatedProp(column.statLabel, column.labelZh, column.labelPt)}}</div>
                            </div>

                            <div v-if="getTranslatedProp(column.subHeading, column.subHeadingZh, column.subHeadingPt)" class="content-popup-2__sub-heading" v-html="getTranslatedProp(column.subHeading, column.subHeadingZh, column.subHeadingPt)"></div>

                            <div v-if="getTranslatedProp(column.text, column.textZh, column.textPt)" class="content-popup-2__text" v-html="getTranslatedProp(column.text, column.textZh, column.textPt)"></div>

                        </div>
                        
                    </div>
                    
                </div>
            
            </div>

            <div v-if="slides && slides.length > 1" class="content-popup-2__slider-controls">
                <div class="content-popup-2__slider-control-arrow-left" @click="prevSlide"></div>
                <div v-for="slide, i in slides" class="content-popup-2__slider-control-dot" :class="{'content-popup-2__slider-control-dot--current': i === currentSlideIndex}" @click="goToSlide(i)" :key="i"></div>
                <div class="content-popup-2__slider-control-arrow-right" @click="nextSlide"></div>
            </div>

        </div>


      </div>
  </Popup>

</template>

<script>
import Popup from '@/components/Popup';
import * as languageHelper from '@/helpers/languageHelper';

export default {
  name: 'ContentPopup2',
  components: {Popup},
  props: {
      heading: String,
      headingZh: String,
      headingPt: String,
      slides: Array
  },
  data()
  {
    return {
      currentSlideIndex: 0
    }
  },
  computed:
  {
    currentLang()
    {
      return languageHelper.getLanguage();
    },
    headingTranslated()
    {
        return this.getTranslatedProp(this.heading, this.headingZh, this.headingPt);
    }
  },
  methods:
  {
      goToSlide(slideIndex)
      {
          this.currentSlideIndex = slideIndex;
      },
      prevSlide()
    {
        if (this.currentSlideIndex > 0)
        {
            this.currentSlideIndex--;
        }
    },
    nextSlide()
    {
        if (this.currentSlideIndex+1 < this.slides.length)
        {
            this.currentSlideIndex++;
        }
    },
    getTranslatedProp(propEn, propZh, propPt)
    {
      var currentLangauge = languageHelper.getLanguage();

      if (currentLangauge === 'zh')
      {
        return propZh;
      }
      else if (currentLangauge === 'pt')
      {
        return propPt;
      }

      return propEn;
    },
  },
}
</script>
