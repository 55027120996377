<template>

  <transition name="fade" mode="out-in">
  <div class="main-navigation" v-show="!isHidden" v-if="(breadcrumbs.length > 1)">
      <div 
        class="main-navigation__button main-navigation__button--back"
        @click="goBack"></div>

      <div class="main-navigation__button main-navigation__button--profile" :class="{'main-navigation__button--dark': profilePopupIsOpen}" @click="toggleProfilePopup"></div>
      
      <div class="main-navigation__button" :class="['main-navigation__button--'+currentRegion, {'main-navigation__button--dark': regionMenuIsOpen || breadcrumbs.length === 2}, {'main-navigation__button--disabled': !canChangeRegion}]" @click="toggleRegionMenu"></div>
      <div class="main-navigation__group" v-show="regionMenuIsOpen">
        <router-link v-if="!isCurrentRegion('general')" class="main-navigation__button main-navigation__button--dark main-navigation__button--general" :to="{path: getPathToRegion('general'), query: getPersistentQuery()}"></router-link>
        <router-link v-if="!isCurrentRegion('uk')" class="main-navigation__button main-navigation__button--dark main-navigation__button--uk" :to="{path: getPathToRegion('uk'), query: getPersistentQuery()}"></router-link>
        <router-link v-if="!isCurrentRegion('usa')" class="main-navigation__button main-navigation__button--dark main-navigation__button--usa" :to="{path: getPathToRegion('usa'), query: getPersistentQuery()}"></router-link>
        <router-link v-if="!isCurrentRegion('china')" class="main-navigation__button main-navigation__button--dark main-navigation__button--china" :to="{path: getPathToRegion('china'), query: getPersistentQuery()}"></router-link>
        <router-link v-if="!isCurrentRegion('japan')" class="main-navigation__button main-navigation__button--dark main-navigation__button--japan" :to="{path: getPathToRegion('japan'), query: getPersistentQuery()}"></router-link>
        <router-link v-if="!isCurrentRegion('brazil')" class="main-navigation__button main-navigation__button--dark main-navigation__button--brazil" :to="{path: getPathToRegion('brazil'), query: getPersistentQuery()}"></router-link>
      </div>

  </div>
  </transition>

  <transition name="fade" mode="out-in">
    <div class="main-navigation" v-show="!isHidden" v-if="!(breadcrumbs.length > 2)">

        <div 
          class="main-navigation__button main-navigation__button--back main-navigation__button--dark" 
          @click="logOut"></div>

          <div class="main-navigation__button main-navigation__button--profile main-navigation__button--dark" @click="toggleProfilePopup"></div>

    </div>
  </transition>

  <Popup v-if="profilePopupIsOpen" type="profiles" color="grey" :opaque="!(breadcrumbs.length > 2)" @close="toggleProfilePopup">
    <div class="segment-description" :class="['segment-description--'+segmentDescriptionRegionModifier]">
      <div class="segment-description__column segment-description__column--enthusiast">
        <div class="segment-description__heading" v-html="getSegmentHeading('enthusiast')"></div>
        <div class="segment-description__text" v-html="getSegmentText('enthusiast')"></div>
        <div class="segment-description__demographics">
          <div class="segment-description__demographics-heading" v-html="s_('profilesPopup.demographicHeading')"></div>
          <div v-html="getSegmentDemographicText('enthusiast')"></div>
          <div class="segment-description__percentage percentage-blob">
              <div class="percentage-blob__value">{{getSegmentPercentage('enthusiast')}}</div>
              <div class="percentage-blob__symbol">%</div>
              <div class="percentage-blob__text" v-html="populationString"></div>
          </div>
        </div>
      </div>
      <div class="segment-description__column segment-description__column--easy-health">
        <div class="segment-description__heading" v-html="getSegmentHeading('easyHealth')"></div>
        <div class="segment-description__text" v-html="getSegmentText('easyHealth')"></div>
        <div class="segment-description__demographics">
          <div class="segment-description__demographics-heading" v-html="s_('profilesPopup.demographicHeading')"></div>
          <div v-html="getSegmentDemographicText('easyHealth')"></div>
          <div class="segment-description__percentage percentage-blob">
              <div class="percentage-blob__value">{{getSegmentPercentage('easyHealth')}}</div>
              <div class="percentage-blob__symbol">%</div>
              <div class="percentage-blob__text" v-html="populationString"></div>
          </div>
        </div>
      </div>
      <div class="segment-description__column segment-description__column--healthy-feel-goods">
        <div class="segment-description__heading" v-html="getSegmentHeading('healthyFeelGoods')"></div>
        <div class="segment-description__text" v-html="getSegmentText('healthyFeelGoods')"></div>
        <div class="segment-description__demographics">
          <div class="segment-description__demographics-heading" v-html="s_('profilesPopup.demographicHeading')"></div>
          <div v-html="getSegmentDemographicText('healthyFeelGoods')"></div>
          <div class="segment-description__percentage percentage-blob">
              <div class="percentage-blob__value">{{getSegmentPercentage('healthyFeelGoods')}}</div>
              <div class="percentage-blob__symbol">%</div>
              <div class="percentage-blob__text" v-html="populationString"></div>
          </div>
        </div>
      </div>
    </div>
  </Popup>

</template>

<script>
import {goToParentScene, goToPath, goToPathByID, getBreadcrumbs, getUrlInfo, getPersistentQuery} from '@/helpers/navigationHelper';
// import toggleFullscreen from '@/helpers/fullscreenHelper';
import eventBus from '@/helpers/eventBus';
import * as languageHelper from '@/helpers/languageHelper';
import Popup from '@/components/Popup';

export default {
  name: 'MainNavigation',
  components: {Popup},
  data()
  {
    return {
      isHidden: false,
      segmentDescriptions: {},
      getPersistentQuery: getPersistentQuery
    }
  },
  computed:
  {
    populationString()
    {
      var region = getUrlInfo().region;
      if (!region) region = 'general';

      var result = '';

      if (region === 'general')
      {
        result = this.s_('profilesPopup.ofTheGlobalPop');
      }
      else if (region === 'uk')
      {
        result = this.s_('profilesPopup.ofTheUkPop');
      }
      else if (region === 'usa')
      {
        result = this.s_('profilesPopup.ofTheUsPop');
      }
      else if (region === 'china')
      {
        result = this.s_('profilesPopup.ofTheChinesePop');
      }
      else if (region === 'japan')
      {
        result = this.s_('profilesPopup.ofTheJapanesePop');
      }
      else if (region === 'brazil')
      {
        result = this.s_('profilesPopup.ofTheBrazilianPop');
      }

      return result;
    },
    currentRegion()
    {
      return getUrlInfo().region;
    },
    segmentDescriptionRegionModifier()
    {
      var result = 'global'

      switch (this.currentRegion)
      {
        case 'uk':
          result = 'uk'
          break;
        case 'usa':
          result = 'usa'
          break;
        case 'japan':
        case 'china':
          result = 'china-japan'
          break;
          case 'brazil':
            result = 'brazil'
            break;
        case 'general':
        default:
          break;
      }
      
      return result;
    },
    isLoading()
    {
      return this.$store.state.isLoading;
    },
    breadcrumbs()
    {
      return getBreadcrumbs();
    },
    profilePopupIsOpen()
    {
      return (this.$route.query.profilePopupIsOpen)
    },
    regionMenuIsOpen()
    {
      return (this.$route.query.regionMenuIsOpen)
    },
    canChangeRegion()
    {
      // NOTE: For chinese and portuguese we lock the region to china and brazil respectively.
      return (this.$route.query.lang !== 'zh' && this.$route.query.lang !== 'pt');
    }
  },
  methods:
  {
    s_(stringId)
    {
        return languageHelper.getSystemString(stringId);
    },
    getTranslatedProp(propEn, propZh, propPt)
    {
      var currentLangauge = languageHelper.getLanguage();

      if (currentLangauge === 'zh')
      {
        return propZh;
      }
      else if (currentLangauge === 'pt')
      {
        return propPt;
      }

      return propEn;
    },
    toggleProfilePopup()
    {
      // NOTE: Close if already open
      if (this.profilePopupIsOpen)
      {
        // NOTE: Make sure to retain all other params here
        /* eslint-disable no-unused-vars */
        var { profilePopupIsOpen, ...queryMinusSelectedProfile } = this.$route.query;
        /* eslint-enable no-unused-vars */

          this.$router.replace({ path: this.$route.path, query: queryMinusSelectedProfile});
      }
      // NOTE: Open
      else {
          this.$router.replace({ path: this.$route.path, query: { profilePopupIsOpen: true, ...this.$route.query}});
      }
    },
    toggleRegionMenu()
    {
      if (!this.canChangeRegion)
      {
        return;
      }

      // NOTE: Close if already open
      if (this.regionMenuIsOpen)
      {
        // NOTE: Make sure to retain all other params here
        /* eslint-disable no-unused-vars */
        var { regionMenuIsOpen, ...queryMinusSelectedProfile } = this.$route.query;
        /* eslint-enable no-unused-vars */

          this.$router.replace({ path: this.$route.path, query: queryMinusSelectedProfile});
      }
      // NOTE: Open
      else {
          this.$router.replace({ path: this.$route.path, query: { regionMenuIsOpen: true, ...this.$route.query}});
      }
    },
    logOut()
    {
      this.$store.commit('logout');
      goToPath('/login');
    },
    isCurrentRegion(region)
    {
      return (region === getUrlInfo().region);
    },
    getSegmentHeading(segmentName)
    {
      var value = '';

      if (segmentName === 'enthusiast') {value = this.getTranslatedProp(this.segmentDescriptions.theEnthusiastHeading, this.segmentDescriptions.theEnthusiastHeadingZh, this.segmentDescriptions.theEnthusiastHeadingPt)}
      else if (segmentName === 'easyHealth') {value = this.getTranslatedProp(this.segmentDescriptions.easyHealthHeading,this.segmentDescriptions.easyHealthHeadingZh, this.segmentDescriptions.easyHealthHeadingPt)}
      else if (segmentName === 'healthyFeelGoods') {value = this.getTranslatedProp(this.segmentDescriptions.healthyFeelGoodsHeading, this.segmentDescriptions.healthyFeelGoodsHeadingZh, this.segmentDescriptions.healthyFeelGoodsHeadingPt)}

      return value;
    },
    getSegmentText(segmentName)
    {
      var value = '';

      var region = getUrlInfo().region;
      if (!region) region = 'general';

      if (region === 'general')
      {
        if (segmentName === 'enthusiast') {value = this.segmentDescriptions.generalTheEnthusiastText}
        else if (segmentName === 'easyHealth') {value = this.segmentDescriptions.generalEasyHealthText}
        else if (segmentName === 'healthyFeelGoods') {value = this.segmentDescriptions.generalHealthyFeelGoodsText}
      }
      else if (region === 'uk')
      {
        if (segmentName === 'enthusiast') {value = this.segmentDescriptions.ukTheEnthusiastText}
        else if (segmentName === 'easyHealth') {value = this.segmentDescriptions.ukEasyHealthText}
        else if (segmentName === 'healthyFeelGoods') {value = this.segmentDescriptions.ukHealthyFeelGoodsText}
      }
      else if (region === 'usa')
      {
        if (segmentName === 'enthusiast') {value = this.segmentDescriptions.usaTheEnthusiastText}
        else if (segmentName === 'easyHealth') {value = this.segmentDescriptions.usaEasyHealthText}
        else if (segmentName === 'healthyFeelGoods') {value = this.segmentDescriptions.usaHealthyFeelGoodsText}
      }
      else if (region === 'china')
      {
        if (segmentName === 'enthusiast') {value = this.getTranslatedProp(this.segmentDescriptions.chinaTheEnthusiastText, this.segmentDescriptions.chinaTheEnthusiastTextZh, this.segmentDescriptions.chinaTheEnthusiastText)}
        else if (segmentName === 'easyHealth') {value = this.getTranslatedProp(this.segmentDescriptions.chinaEasyHealthText, this.segmentDescriptions.chinaEasyHealthTextZh, this.segmentDescriptions.chinaEasyHealthText)}
        else if (segmentName === 'healthyFeelGoods') {value = this.getTranslatedProp(this.segmentDescriptions.chinaHealthyFeelGoodsText, this.segmentDescriptions.chinaHealthyFeelGoodsTextZh, this.segmentDescriptions.chinaHealthyFeelGoodsText)}
      }
      else if (region === 'japan')
      {
        if (segmentName === 'enthusiast') {value = this.segmentDescriptions.japanTheEnthusiastText}
        else if (segmentName === 'easyHealth') {value = this.segmentDescriptions.japanEasyHealthText}
        else if (segmentName === 'healthyFeelGoods') {value = this.segmentDescriptions.japanHealthyFeelGoodsText}
      }
      else if (region === 'brazil')
      {
        if (segmentName === 'enthusiast') {value = this.getTranslatedProp(this.segmentDescriptions.brazilTheEnthusiastText, this.segmentDescriptions.brazilTheEnthusiastText, this.segmentDescriptions.brazilTheEnthusiastTextPt)}
        else if (segmentName === 'easyHealth') {value = this.getTranslatedProp(this.segmentDescriptions.brazilEasyHealthText, this.segmentDescriptions.brazilEasyHealthText, this.segmentDescriptions.brazilEasyHealthTextPt)}
        else if (segmentName === 'healthyFeelGoods') {value = this.getTranslatedProp(this.segmentDescriptions.brazilHealthyFeelGoodsText, this.segmentDescriptions.brazilHealthyFeelGoodsText, this.segmentDescriptions.brazilHealthyFeelGoodsTextPt)}
      }
    
      return value;
    },
    getSegmentDemographicText(segmentName)
    {
      var value = '';

      var region = getUrlInfo().region;
      if (!region) region = 'general';

      if (region === 'general')
      {
        if (segmentName === 'enthusiast') {value = this.segmentDescriptions.generalTheEnthusiastDemographics}
        else if (segmentName === 'easyHealth') {value = this.segmentDescriptions.generalEasyHealthDemographics}
        else if (segmentName === 'healthyFeelGoods') {value = this.segmentDescriptions.generalHealthyFeelGoodsDemographics}
      }
      else if (region === 'uk')
      {
        if (segmentName === 'enthusiast') {value = this.segmentDescriptions.ukTheEnthusiastDemographics}
        else if (segmentName === 'easyHealth') {value = this.segmentDescriptions.ukEasyHealthDemographics}
        else if (segmentName === 'healthyFeelGoods') {value = this.segmentDescriptions.ukHealthyFeelGoodsDemographics}
      }
      else if (region === 'usa')
      {
        if (segmentName === 'enthusiast') {value = this.segmentDescriptions.usaTheEnthusiastDemographics}
        else if (segmentName === 'easyHealth') {value = this.segmentDescriptions.usaEasyHealthDemographics}
        else if (segmentName === 'healthyFeelGoods') {value = this.segmentDescriptions.usaHealthyFeelGoodsDemographics}
      }
      else if (region === 'china')
      {
        if (segmentName === 'enthusiast') {value = this.getTranslatedProp(this.segmentDescriptions.chinaTheEnthusiastDemographics, this.segmentDescriptions.chinaTheEnthusiastDemographicsZh, this.segmentDescriptions.chinaTheEnthusiastDemographics)}
        else if (segmentName === 'easyHealth') {value = this.getTranslatedProp(this.segmentDescriptions.chinaEasyHealthDemographics, this.segmentDescriptions.chinaEasyHealthDemographicsZh, this.segmentDescriptions.chinaEasyHealthDemographics)}
        else if (segmentName === 'healthyFeelGoods') {value = this.getTranslatedProp(this.segmentDescriptions.chinaHealthyFeelGoodsDemographics, this.segmentDescriptions.chinaHealthyFeelGoodsDemographicsZh, this.segmentDescriptions.chinaHealthyFeelGoodsDemographics)}
      }
      else if (region === 'japan')
      {
        if (segmentName === 'enthusiast') {value = this.segmentDescriptions.japanTheEnthusiastDemographics}
        else if (segmentName === 'easyHealth') {value = this.segmentDescriptions.japanEasyHealthDemographics}
        else if (segmentName === 'healthyFeelGoods') {value = this.segmentDescriptions.japanHealthyFeelGoodsDemographics}
      }
      else if (region === 'brazil')
      {
        if (segmentName === 'enthusiast') {value = this.getTranslatedProp(this.segmentDescriptions.brazilTheEnthusiastDemographics, this.segmentDescriptions.brazilTheEnthusiastDemographics, this.segmentDescriptions.brazilTheEnthusiastDemographicsPt)}
        else if (segmentName === 'easyHealth') {value = this.getTranslatedProp(this.segmentDescriptions.brazilEasyHealthDemographics, this.segmentDescriptions.brazilEasyHealthDemographics, this.segmentDescriptions.brazilEasyHealthDemographicsPt)}
        else if (segmentName === 'healthyFeelGoods') {value = this.getTranslatedProp(this.segmentDescriptions.brazilHealthyFeelGoodsDemographics, this.segmentDescriptions.brazilHealthyFeelGoodsDemographics, this.segmentDescriptions.brazilHealthyFeelGoodsDemographicsPt)}
      }

      return value;
    },
    getSegmentPercentage(segmentName)
    {
      var value = '';

      var region = getUrlInfo().region;
      if (!region) region = 'general';

      if (region === 'general')
      {
        if (segmentName === 'enthusiast') {value = this.segmentDescriptions.generalTheEnthusiastPercentage}
        else if (segmentName === 'easyHealth') {value = this.segmentDescriptions.generalEasyHealthPercentage}
        else if (segmentName === 'healthyFeelGoods') {value = this.segmentDescriptions.generalHealthyFeelGoodsPercentage}
      }
      else if (region === 'uk')
      {
        if (segmentName === 'enthusiast') {value = this.segmentDescriptions.ukTheEnthusiastPercentage}
        else if (segmentName === 'easyHealth') {value = this.segmentDescriptions.ukEasyHealthPercentage}
        else if (segmentName === 'healthyFeelGoods') {value = this.segmentDescriptions.ukHealthyFeelGoodsPercentage}
      }
      else if (region === 'usa')
      {
        if (segmentName === 'enthusiast') {value = this.segmentDescriptions.usaTheEnthusiastPercentage}
        else if (segmentName === 'easyHealth') {value = this.segmentDescriptions.usaEasyHealthPercentage}
        else if (segmentName === 'healthyFeelGoods') {value = this.segmentDescriptions.usaHealthyFeelGoodsPercentage}
      }
      else if (region === 'china')
      {
        if (segmentName === 'enthusiast') {value = this.segmentDescriptions.chinaTheEnthusiastPercentage}
        else if (segmentName === 'easyHealth') {value = this.segmentDescriptions.chinaEasyHealthPercentage}
        else if (segmentName === 'healthyFeelGoods') {value = this.segmentDescriptions.chinaHealthyFeelGoodsPercentage}
      }
      else if (region === 'japan')
      {
        if (segmentName === 'enthusiast') {value = this.segmentDescriptions.japanTheEnthusiastPercentage}
        else if (segmentName === 'easyHealth') {value = this.segmentDescriptions.japanEasyHealthPercentage}
        else if (segmentName === 'healthyFeelGoods') {value = this.segmentDescriptions.japanHealthyFeelGoodsPercentage}
      }
      else if (region === 'brazil')
      {
        if (segmentName === 'enthusiast') {value = this.segmentDescriptions.brazilTheEnthusiastPercentage}
        else if (segmentName === 'easyHealth') {value = this.segmentDescriptions.brazilEasyHealthPercentage}
        else if (segmentName === 'healthyFeelGoods') {value = this.segmentDescriptions.brazilHealthyFeelGoodsPercentage}
      }

      return value;
    },
    goBack()
    {
      goToParentScene();
    },
    goToPathByID(id)
    {
        goToPathByID(id);
    },
    setLanguage(languageiD)
    {
      languageHelper.setLanguage(languageiD);
    },
    /*
    toggleFullscreen()
    {
        toggleFullscreen();
    },
    */
    getPathToRegion(regionSlug)
    {
      // NOTE: Build a new url where the region segment is replaced by the new region
      var newRoute = '/';
      this.$route.params.pathMatch.forEach((segment, i)=>{
        if (segment)
        {
          if (i === 0)
          {
            segment = regionSlug;
          }
          newRoute += segment + '/';
        }
      });

      return newRoute;
    },
    getPathToProfile(profileSlug)
    {
      // NOTE: Build a new url where the region segment is replaced by the new region
      var newRoute = '/';
      this.$route.params.pathMatch.forEach((segment, i)=>{
        if (segment)
        {
          if (i === 1)
          {
            segment = profileSlug;
          }
          newRoute += segment + '/';
        }
      });

      return newRoute;
    }
  },
  created()
  {
    this.events = {
      onHide: ()=>{
        this.isHidden = true;
      },
      onUnHide: ()=>{
        this.isHidden = false;
      }
    }

    this.segmentDescriptions = this.$store.state.segmentDescriptions;

    eventBus.on('mainNavigation.hide', this.events.onHide);
    eventBus.on('mainNavigation.unHide', this.events.onUnHide);

  },
  unmounted()
  {
    eventBus.off('mainNavigation.hide', this.events.onHide);
    eventBus.off('mainNavigation.unHide', this.events.onUnHide);
  }
}
</script>