<template>
  <AspectRatio>
    
    <transition name="fade" mode="out-in">
      <LoadingScreen v-if="isLoading"></LoadingScreen>
    </transition>

    <router-view></router-view>
    <VideoTransitions/>

  </AspectRatio>
</template>


<script>
import './scss/main.scss';
import AspectRatio from '@/components/AspectRatio';
import LoadingScreen from '@/components/LoadingScreen';
import VideoTransitions from '@/components/VideoTransitions';

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

export default {
  name: 'App',
  components:
  {
    AspectRatio: AspectRatio,
    LoadingScreen: LoadingScreen,
    VideoTransitions: VideoTransitions
  },
  data()
  {
    return {}
  },
  computed: 
  {
    isLoggedIn()
    {
      return this.$store.state.isLoggedIn;
    },
    isLoading()
    {
      return this.$store.state.isLoading;
    }
  },
  created()
  {
    // NOTE: Tell our Vuex store to load the content
    this.$store.dispatch('loadManifest');
  }
}
</script>