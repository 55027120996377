<template>
    <div class="character-marker" :class="'character-marker--color-'+color" :style="'left:'+x+'px;top:'+y+'px;'">
      <div class="character-marker__offset">
        <div class="character-marker__pin"></div>
        <div class="character-marker__wings"></div>
      </div>
    </div>
</template>

<script>
import {getUrlInfo} from '@/helpers/navigationHelper';

export default {
  name: 'CharacterMarker',
  props: {
      x: Number,
      y: Number
  },
  methods: {},
  computed: {
    color()
    {
      var color = '';

      var profile = getUrlInfo().profile;

      if (profile === 'performance-nutrition') {color = 'red'}
      else if (profile === 'active-nutrition') {color = 'blue'}
      else if (profile === 'lifestyle-nutrition') {color = 'yellow'}

      return color;
    }
  }
}
</script>