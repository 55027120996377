<template>
    <div class="profile-location-title" :class="'profile-location-title--'+profile">
      <span v-html="content"></span>
    </div>
</template>

<script>
import {getUrlInfo} from '@/helpers/navigationHelper';
import * as languageHelper from '@/helpers/languageHelper';

export default {
  name: 'ProfileLocationTitle',
  props: {},
  methods: {
    s_(stringId)
    {
        return languageHelper.getSystemString(stringId);
    }
  },
  computed: {
    profile()
    {
      return getUrlInfo().profile;
    },
    content()
    {
      var html = '';

      var urlInfo = getUrlInfo();

      if (urlInfo.profile === 'performance-nutrition') 
      {
        if (urlInfo.location == 'at-home') {html = this.s_('profileLocationTitle.enthusiastHome');}
        else if (urlInfo.location == 'when-exercising') {html = this.s_('profileLocationTitle.enthusiastGym');}
        else if (urlInfo.location == 'at-the-supermarket') {html = this.s_('profileLocationTitle.enthusiastSupermarket');}
      }
      else if (urlInfo.profile === 'active-nutrition') 
      {
        if (urlInfo.location == 'at-home') {html = this.s_('profileLocationTitle.easyHealthHome');}
        else if (urlInfo.location == 'when-exercising') {html = this.s_('profileLocationTitle.easyHealthGym');}
        else if (urlInfo.location == 'at-the-supermarket') {html = this.s_('profileLocationTitle.easyHealthSupermarket');}
      }
      else if (urlInfo.profile === 'lifestyle-nutrition') 
      {
        if (urlInfo.location == 'at-home') {html = this.s_('profileLocationTitle.healthyFeelGoodHome');}
        else if (urlInfo.location == 'when-exercising') {html = this.s_('profileLocationTitle.healthyFeelGoodenthusiastGym');}
        else if (urlInfo.location == 'at-the-supermarket') {html = this.s_('profileLocationTitle.healthyFeelGoodSupermarket');}
      }

      return html;
    }
  }
}
</script>