import { createRouter, createWebHashHistory } from 'vue-router'
import SceneView from '../views/SceneView.vue'
import LoginView from '../views/LoginView.vue'
import store from '@/store';
import eventBus from '@/helpers/eventBus';
import {getCookie} from '@/helpers/cookieHelper';

const routes = [
  {
    name: "login",
    path: "/login",
    component: LoginView
  },
  {
    name: "scenes",
    path: "/:pathMatch(.*)*",
    component: SceneView
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach((to, from, next) => {

  // NOTE: If not loggedin
  if (!store.state.isLoggedIn && to.name !== 'login')
  {
    let query = to.query;
    if (to.path !== '/')
    {
      query.loginRedirect = to.path;
    }
    
    next({path: '/login', query: query});
    return;
  }

  if (to.path === '/')
  {
    console.log('Blank page, no good');

    let cookieLang = getCookie('lang');
    let query = to.query;

    if (cookieLang && cookieLang === 'zh')
    {
      next({path: '/china/', query: query});
    }
    if (cookieLang && cookieLang === 'pt')
    {
      next({path: '/brazil/', query: query});
    }    
    else
    {
      next({path: '/general/', query: query});
    }

    return;
  }

  // NOTE: Trigger video transitions.
  if (from.path === '/login' && to.path === '/general/')
  {
    eventBus.emit('videoTransitionManager.trigger', 'login-video-global');
  }
  else if (from.path === '/login' && to.path === '/uk/')
  {
    eventBus.emit('videoTransitionManager.trigger', 'login-video-uk');
  }
  else if (from.path === '/login' && to.path === '/usa/')
  {
    eventBus.emit('videoTransitionManager.trigger', 'login-video-usa');
  }
  else if (from.path === '/login' && to.path === '/china/')
  {
    eventBus.emit('videoTransitionManager.trigger', 'login-video-china');
  }
  else if (from.path === '/login' && to.path === '/japan/')
  {
    eventBus.emit('videoTransitionManager.trigger', 'login-video-japan');
  }
  else if (from.path === '/login' && to.path === '/brazil/')
  {
    eventBus.emit('videoTransitionManager.trigger', 'login-video-brazil');
  }
  else if (
    (from.path === '/general/'&& to.path === '/general/performance-nutrition/') ||
    (from.path === '/general/'&& to.path === '/general/active-nutrition/') ||
    (from.path === '/general/'&& to.path === '/general/lifestyle-nutrition/')
  )
  {
    eventBus.emit('videoTransitionManager.trigger', 'profiles-city-video');
  }

  // NOTE: Add language parameter if not set
  if (!to.query.lang)
  {
    const defaultLanguage = 'en';
    let query = to.query;
    query.lang = defaultLanguage;

    var cookieLang = getCookie('lang');
    if (cookieLang)
    {
      query.lang = cookieLang
    }

    next({path: to.path, query: query});
  }
  else
  {
    next();
  }
});

export default router;