<template>

    <!-- NOTE: We manually map data to vue components here -->

    <template v-for="(sceneComponent, i) in sceneComponents" :key="i">

      <HotspotButton v-if="sceneComponent.nsAlias === 'hotspotButton'" :key="i"
        :x="sceneComponent.positionX"
        :y="sceneComponent.positionY"
        :title="getTranslatedProp(sceneComponent.title, sceneComponent.titleZh, sceneComponent.titlePt)"
        :target-id="sceneComponent.sceneContentReference"
        :external-url="sceneComponent.externalURL"
        :has-transition="true"
        :has-pulse="true"
        :transition-delay-ms="hotspotDelaysByIndex[i]"
        :pulse-interval-ms="hotspotDelaysByIndex[i] + pulseIntervalMs"
        :icon="sceneComponent.icon"
        :direction="(sceneComponent.flipDirection) ? 'right' : 'left'"
        :color="sceneComponent.color"
        :fixedWidth="sceneComponent.fixedWidth"
      />

      <BackgroundVideo v-if="sceneComponent.nsAlias === 'backgroundVideo'" :key="i"
        :positionCss="sceneComponent.positionCss"
        :videoFrameCss="sceneComponent.videoFrameCss"
        :videoPreviewFile="sceneComponent.videoPreviewFile"
        :videoFile="sceneComponent.videoFile"
        :videoLink="sceneComponent.videoLink"
      />

      <Logo v-if="sceneComponent.nsAlias === 'logo'" key="i"
        :css="sceneComponent.css"
        :style="sceneComponent.style"
      />

      <ProfileLocationTitle v-if="sceneComponent.nsAlias === 'profileLocationTitle'" key="i"/>

      <CharacterMarker v-if="sceneComponent.nsAlias === 'characterMarker'" key="i"
        :x="sceneComponent.positionX"
        :y="sceneComponent.positionY"
      />

      <DeepDataLink v-if="sceneComponent.nsAlias === 'deepDataLink'" key="i"
        :text="sceneComponent.text"
      />

      <LightData v-if="sceneComponent.nsAlias === 'lightData'" key="i"
        :titleID="sceneComponent.titleID"
        :hotspotTitle="getTranslatedProp(sceneComponent.hotspotTitle, sceneComponent.hotspotTitleZh, sceneComponent.hotspotTitlePt)"
        :x="sceneComponent.positionX"
        :y="sceneComponent.positionY"
        :direction="(sceneComponent.flipDirection) ? 'right' : 'left'"
        :lightDataType="sceneComponent.lightDataType"
        :dataReference="sceneComponent.dataReference"
        :dataValuesToInclude="sceneComponent.dataValuesToInclude"
        :dataBarLengthBase="sceneComponent.dataBarLengthBase"
        :dataBarLengthPercentage="sceneComponent.dataBarLengthPercentage"
        :deepDataLinkText="getTranslatedProp(sceneComponent.deepDataLinkText, sceneComponent.deepDataLinkTextZh, sceneComponent.deepDataLinkTextPt)"
        :quoteText="getTranslatedProp(sceneComponent.quoteText, sceneComponent.quoteTextZh, sceneComponent.quoteTextPt)"
        :emotionalDrivers1Title="getTranslatedProp(sceneComponent.emotionalDrivers1Title, sceneComponent.emotionalDrivers1TitleZh, sceneComponent.emotionalDrivers1TitlePt)"
        :emotionalDrivers1Text="getTranslatedProp(sceneComponent.emotionalDrivers1Text, sceneComponent.emotionalDrivers1TextZh, sceneComponent.emotionalDrivers1TextPt)"
        :emotionalDrivers1Icon="sceneComponent.emotionalDrivers1Icon"
        :emotionalDrivers1Percentage="sceneComponent.emotionalDrivers1Percentage"
        :emotionalDrivers2Title="getTranslatedProp(sceneComponent.emotionalDrivers2Title, sceneComponent.emotionalDrivers2TitleZh, sceneComponent.emotionalDrivers2TitlePt)"
        :emotionalDrivers2Text="getTranslatedProp(sceneComponent.emotionalDrivers2Text, sceneComponent.emotionalDrivers2TextZh, sceneComponent.emotionalDrivers2TextPt)"
        :emotionalDrivers2Icon="sceneComponent.emotionalDrivers2Icon"
        :emotionalDrivers2Percentage="sceneComponent.emotionalDrivers2Percentage"
        :emotionalDrivers3Title="getTranslatedProp(sceneComponent.emotionalDrivers3Title, sceneComponent.emotionalDrivers3TitleZh, sceneComponent.emotionalDrivers3TitlePt)"
        :emotionalDrivers3Text="getTranslatedProp(sceneComponent.emotionalDrivers3Text, sceneComponent.emotionalDrivers3TextZh, sceneComponent.emotionalDrivers3TextPt)"
        :emotionalDrivers3Icon="sceneComponent.emotionalDrivers3Icon"
        :emotionalDrivers3Percentage="sceneComponent.emotionalDrivers3Percentage"
      />

      <ProfileSelection v-if="sceneComponent.nsAlias === 'profileSelection'" key="i"
        :theEnthusiastsLinkEn="sceneComponent.theEnthusiastsLinkEn"
        :healthyFeelGoodsLinkEn="sceneComponent.healthyFeelGoodsLinkEn"
        :easyHealthLinkEn="sceneComponent.easyHealthLinkEn"

        :theEnthusiastLinkZh="sceneComponent.theEnthusiastLinkZh"
        :healthyFeelGoodsLinkZh="sceneComponent.healthyFeelGoodsLinkZh"
        :easyHealthLinkZh="sceneComponent.easyHealthLinkZh"

        :theEnthusiastLinkPt="sceneComponent.theEnthusiastLinkPt"
        :healthyFeelGoodsLinkPt="sceneComponent.healthyFeelGoodsLinkPt"
        :easyHealthLinkPt="sceneComponent.easyHealthLinkPt"

        :whyDataMattersLink="sceneComponent.whyDataMattersLink"
        :methodologyLink="sceneComponent.methodologyLink"

        :theEnthusiastHeading="getTranslatedProp(sceneComponent.theEnthusiastHeading, sceneComponent.theEnthusiastHeadingZh, sceneComponent.theEnthusiastHeadingPt)"
        :theEnthusiastText="getTranslatedProp(sceneComponent.theEnthusiastText, sceneComponent.theEnthusiastTextZh, sceneComponent.theEnthusiastTextPt)"
        :theEnthusiastStatText="getTranslatedProp(sceneComponent.theEnthusiastStatText, sceneComponent.theEnthusiastStatTextZh, sceneComponent.theEnthusiastStatTextPt)"
        :theEnthusiastGlobalPopulationPercentage="sceneComponent.theEnthusiastGlobalPopulationPercentage"

        :healthyFeelGoodsHeading="getTranslatedProp(sceneComponent.healthyFeelGoodsHeading, sceneComponent.healthyFeelGoodsHeadingZh, sceneComponent.healthyFeelGoodsHeadingPt)"
        :healthyFeelGoodsText="getTranslatedProp(sceneComponent.healthyFeelGoodsText, sceneComponent.healthyFeelGoodsTextZh, sceneComponent.healthyFeelGoodsTextPt)"
        :healthyFeelGoodsStatText="getTranslatedProp(sceneComponent.healthyFeelGoodsStatText, sceneComponent.healthyFeelGoodsStatTextZh, sceneComponent.healthyFeelGoodsStatTextPt)"
        :healthyFeelGoodsGlobalPopulationPercentage="sceneComponent.healthyFeelGoodsGlobalPopulationPercentage"

        :easyHealthHeading="getTranslatedProp(sceneComponent.easyHealthHeading, sceneComponent.easyHealthHeadingZh, sceneComponent.easyHealthHeadingPt)"
        :easyHealthText="getTranslatedProp(sceneComponent.easyHealthText, sceneComponent.easyHealthTextZh, sceneComponent.easyHealthTextPt)"
        :easyHealthStatText="getTranslatedProp(sceneComponent.easyHealthStatText, sceneComponent.easyHealthStatTextZh, sceneComponent.easyHealthStatTextPt)"
        :easyHealthGlobalPopulationPercentage="sceneComponent.easyHealthGlobalPopulationPercentage"
      />

    </template>

</template>

<script>
import HotspotButton from '@/components/scene-components/HotspotButton';
import BackgroundVideo from '@/components/scene-components/BackgroundVideo';
import Logo from '@/components/scene-components/Logo';
import ProfileLocationTitle from '@/components/scene-components/ProfileLocationTitle';
import LightData from '@/components/scene-components/LightData';
import DeepDataLink from '@/components/scene-components/DeepDataLink';
import ProfileSelection from '@/components/scene-components/ProfileSelection';
import CharacterMarker from '@/components/scene-components/CharacterMarker';
import * as languageHelper from '@/helpers/languageHelper';

export default {
  name: 'SceneComponentRenderer',
  components: {
    HotspotButton,
    BackgroundVideo,
    Logo,
    ProfileLocationTitle,
    LightData,
    DeepDataLink,
    ProfileSelection,
    CharacterMarker
  },
  props: {
      sceneComponents: Array
  },
  data()
  {
    return {
      initialDelayMs: 400,
      delayOffsetMs: 200,
      pulseIntervalMs: 15000,
      hotspotDelaysByIndex: []
    }
  },
  methods:
  {
    getTranslatedProp(propEn, propZh, propPt)
    {
      var currentLangauge = languageHelper.getLanguage();

      if (currentLangauge === 'zh')
      {
        return propZh;
      }
      else if (currentLangauge === 'pt')
      {
        return propPt;
      }

      return propEn;
    }
  },
  created()
  {
    // NOTE: Populate hotspotIndexes array with data to use to calculate delay time...
    var i = 0;
    this.sceneComponents.forEach((component, j)=>{
      if (component.nsAlias === 'hotspotButton')
      {
        this.hotspotDelaysByIndex[j] = this.initialDelayMs + (i * this.delayOffsetMs);
        i++;
      }
    });
  }
}
</script>
