<template>

    <div class="pie-chart" :class="'pie-chart--color-'+color">
    
        <svg width="1000" height="275">

            <!-- NOTE: In a mask. the black is hidden and the white is visible -->
            <defs>
                <!-- NOTE: Masks the pie chart away from the bars -->
                <mask id="circle">
                    <rect width="100%" height="100%" fill="white"/>
                    <circle r="142.5" cx="137.5" cy="137.5" fill="black"/>
                </mask>
            </defs>

            <!-- NOTE: Draw pie chart -->
            <!--
            <circle r="104.5" cx="137.5" cy="137.5" fill="transparent" stroke-width="66" stroke="beige" />
            -->

            <!-- NOTE: Percentage wedges -->
            <!-- NOTE The circumferance of the radius of 104.5 is 656.59, we use that to calculate the wedges -->


            <circle r="104.5" cx="137.5" cy="137.5" fill="transparent"
                v-for="wedge,i in pieWedges"
                :key="i"
                stroke="orange"
                stroke-width="67"
                :stroke-dasharray="percentageOf(wedge.value, 656.59)+' '+percentageOf(100-wedge.value, 656.59)"
                _stroke-dashoffset="percentageOf(wedge.value, 656.59)"
                _transform="rotate(-30 -400)"
                :transform="'rotate('+(-90 + percentageOf(wedge.offset, 360))+')'"
                transform-origin="137.5 137.5"
                :class="'wedge-'+i"
            /> 

            <!-- NOTE: Draw bars -->
            <rect v-for="row,i in data" :key="row.title" :class="'bar-'+i" x="137.5" :y="70*i" :width="barLengthToUse" height="65" fill="green" mask="url(#circle)" />

        </svg>

        <!-- NOTE: Just render a special version of the bar chart on top here -->
        <div class="pie-chart__bars bar-chart bar-chart--for-pie-chart" :class="'bar-chart--color-'+color">
            <template v-for="row in data" :key="row.title">
                <div class="bar-chart__item" :style="'width: calc('+barLengthToUse+'px - 12px)'">
                    <div class="bar-chart__item-text" :class="{'bar-chart__item-text--too-long': row.title.length > 70}">{{row.title}}</div>
                    <div class="bar-chart__item-value">
                    <span class="bar-chart__number"><span>{{row.value}}</span></span>
                    <span class="bar-chart__percentage">%</span>
                    </div>
                </div>
            </template>
        </div>

    </div>

</template>

<script>
export default {
    name: 'PieChart',
    props: {
        data: Array,
        color: String,
        barLength: Number,
    },
    methods:
    {
        percentageOf(percentage, thingYouWantPercentageOf)
        {
            return ((percentage/100) * thingYouWantPercentageOf);
        }
    },
    computed:
    {
        barLengthToUse()
        {
            return (this.barLength > 0) ? this.barLength : 420;
        },
        pieWedges()
        {
            var result = [];

            var accumulatedOffset = 0;

            // NOTE: Compute the necessary info to render pie wedges
            this.data.forEach((row)=>{
                result.push({
                    value: row.value,
                    offset: accumulatedOffset
                });
                accumulatedOffset += row.value;
            });


            return result;
        }
    }
}
</script>