<template>
    <div class="scene">

        <PreloadedImage v-if="backgroundImage" class="scene__background-image" :src="mediaUrl(backgroundImage)"/>

        <video 
          ref="video"
          v-if="backgroundVideo"
          :src="mediaUrl(backgroundVideo)"
          class="scene__background-video"
          muted
          autoplay
          loop
          playsinline
          webkit-playsinline
          type="video/mp4"
          @contextmenu.prevent=""></video>

        <div class="scene__contents">
          <div class="scene__video-toggle-btn" :class="['scene__video-toggle-btn--'+videoToggleButtonStyle, {'scene__video-toggle-btn--paused': !videoIsPlaying}]" v-if="videoIsReady" @click="toggleVideo"></div>
          <slot></slot>
        </div>

    </div>
</template>


<script>
import { getUmbracoMediaItemSrc } from '@/helpers/assetHelper';
import PreloadedImage from '@/components/PreloadedImage'

export default {
  name: 'Scene',
  components: {
    PreloadedImage
  },
  props: {
      backgroundImage: Object,
      backgroundVideo: Object,
      videoToggleButtonStyle: String
  },
  data()
  {
    return {
      videoIsReady: false,
      videoIsPlaying: false
    }
  },
  methods:
  {
    mediaUrl(image)
    {
      return getUmbracoMediaItemSrc(image);
    },
    toggleVideo()
    {
      if (this.$refs.video)
      {
        if (this.videoIsPlaying)
        {
          this.$refs.video.pause();
        }
        else 
        {
          this.$refs.video.play();
        }
      }
    },
  },
  mounted()
  {
    this.events = {
      play: ()=>
      { 
          this.videoIsPlaying = true;
          if (!this.videoIsReady)
          {
            this.videoIsReady = true;
          }
      },
      pause: ()=>
      {
          this.videoIsPlaying = false;
      },
    };

    if (this.$refs.video)
    {
      this.$refs.video.addEventListener("play", this.events.play);
      this.$refs.video.addEventListener("pause", this.events.pause);
    }
  },
  beforeUnmount()
  {
    if (this.$refs.video)
    {
      this.$refs.video.addEventListener("play", this.events.play);
      this.$refs.video.addEventListener("pause", this.events.pause);
    }
  }
}
</script>
