var config = {
   manifestPath: 'assets/json/en/manifest.json',
   tempMediaPath: 'https://arla-3-segments.azurewebsites.net/output/event/',
   umbracoMediaPath: '',
}
 
if (window.location.hostname === "localhost" )
{
   config.tempMediaPath = '';
   config.manifestPath = 'https://arla-3-segments.azurewebsites.net/output/event/assets/json/en/manifest.json';
   config.umbracoMediaPath = 'https://arla-3-segments.azurewebsites.net';
}
 
config.systemImagesToPreload = [
   'images/slider-nav-left.png',
   'images/slider-nav-right.png',
   'images/arrow-right-white.svg',
   'images/close.svg',
   'images/deep-data-corner.png',
   'images/error-icon.png',
   'images/flag-br.png',
   'images/flag-en.png',
   'images/flag-zh.png',
   'images/hotspot-arrow-left-blue.svg',
   'images/hotspot-arrow-left-grey.svg',
   'images/hotspot-arrow-left-red.svg',
   'images/hotspot-arrow-left-yellow.svg',
   'images/hotspot-arrow-right-blue.svg',
   'images/hotspot-arrow-right-grey.svg',
   'images/hotspot-arrow-right-red.svg',
   'images/hotspot-arrow-right-yellow.svg',
   'images/hotspot-plus-blue.svg',
   'images/hotspot-plus-grey.svg',
   'images/hotspot-plus-red.svg',
   'images/hotspot-plus-yellow.svg',
   'images/light-data-link-blue.png',
   'images/light-data-link-red.png',
   'images/light-data-link-yellow.png',
   'images/play-btn.svg',
   'images/pause-btn.svg',
   'images/play-btn-dark.svg',
   'images/pause-btn-dark.svg',
   'images/logo.png',
   'images/bubble/bubble-large.png',
   'images/bubble/bubble-small.png',
   'images/bubble/bubble-large-flipped.png',
   'images/bubble/bubble-small-flipped.png',
   'images/menu/back.png',
   'images/menu/back-dark.png',
   'images/menu/profiles.png',
   'images/menu/profiles-dark.png',
   'images/menu/region-brazil.png',
   'images/menu/region-brazil-dark.png',
   'images/menu/region-china.png',
   'images/menu/region-china-dark.png',
   'images/menu/region-general.png',
   'images/menu/region-general-dark.png',
   'images/menu/region-japan.png',
   'images/menu/region-japan-dark.png',
   'images/menu/region-uk.png',
   'images/menu/region-uk-dark.png',
   'images/menu/region-usa.png',
   'images/menu/region-usa-dark.png',
   'images/profile-popup/brazil-easy-health.png',
   'images/profile-popup/brazil-healthy-feel-goods.png',
   'images/profile-popup/global-easy-health.png',
   'images/profile-popup/global-enthusiast.png',
   'images/profile-popup/global-healthy-feel-goods.png',
   'images/profile-popup/japan-china-easy-health.png',
   'images/profile-popup/japan-china-enthusiast.png',
   'images/profile-popup/japan-china-healthy-feel-goods.png',
   'images/profile-popup/uk-us-enthusiast.png',
];

export default config;