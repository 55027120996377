<template>
    <div class="bar-chart" :class="'bar-chart--color-'+color">
        <template v-for="row in data" :key="row.title">
            <div class="bar-chart__item" :style="barChartMarginRightStyle(row.value)">
            <div class="bar-chart__item-text" :class="{'bar-chart__item-text--too-long': row.title.length > 80}">{{row.title}}</div>
            <div class="bar-chart__item-value">
                <span class="bar-chart__number"><span>{{row.value}}</span></span>
                <span class="bar-chart__percentage">%</span>
            </div>
            </div>
        </template>
        </div>
</template>

<script>
export default {
    name: 'BarChart',
    props: {
        data: Array,
        color: String,
        barLengthBase: Number,
        barLengthPercentage: Number
    },
    methods:
    {
        barChartMarginRightStyle(value)
        {
            // NOTE: The base width is the minimum width of the bar. The percentageWidthInPixels is how long the variable part is at at value of 100%.
            var baseWidthInPixels = (this.barLengthBase > 0) ? this.barLengthBase : 300;
            var percentageWidthInPixels = (this.barLengthPercentage > 0) ? this.barLengthPercentage : 150;
            var percentageInPixels = parseInt(value) * (percentageWidthInPixels / 100);

            return 'width: calc('+baseWidthInPixels+'px + '+percentageInPixels+'px)';
        }
    },
}
</script>