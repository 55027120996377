<template>
    <div class="hide-toggle" :class="{'hide-toggle--hidden': !isToggled}"></div>
</template>

<script>
export default {
  name: 'HideToggle',
  props: {
      color: String,
      isToggled: Boolean
  },
}
</script>
