import { createStore } from 'vuex'
import config from '@/config';
import { preloadImages, getUmbracoMediaItemSrc } from '@/helpers/assetHelper';
import {getCookie, setCookie, deleteCookie} from '@/helpers/cookieHelper';

export default createStore({

  state:
  {
    isLoggedIn: (getCookie('loggedIn')) ? true : false,
    manifestWasLoaded: false,
    isLoading: false,
    siteInfo: {},
    scenes: [],
    contentComponents: [],
    surveyData: [],
    segmentDescriptions: {},
    sceneTransitionEffect: {type: 'none'},
    sceneViewOffset: {x:0, y:0, transition: 0},
    deepDataAnchorTabelId: null,
    updateHack: false
  },
  
  mutations:
  {
    forceUpdate(state)
    {
      state.updateHack = !state.updateHack;
    },
    login(state, payload)
    {
      setCookie('loggedIn', 'true', 1);
      setCookie('lang', payload.lang, 1)

      state.isLoggedIn = true;
    },

    logout(state)
    {
      state.isLoggedIn = false;
      deleteCookie('loggedIn');
      deleteCookie('lang');
    },

    setLoadingState(state, payload)
    {
      state.isLoading = payload;
    },

    setDeepDataAnchorTabelId(state, payload)
    {
      state.deepDataAnchorTabelId = payload;
    },

    setSceneViewOffset(state, payload)
    {
      state.sceneViewOffset.x = payload.x;
      state.sceneViewOffset.y = payload.y;
      state.sceneViewOffset.transition = payload.transition;
    },

    setSceneTransitionEffect(state, payload)
    {
      state.sceneTransitionEffect = {
        type: payload.type,
        transformOrigin: payload.transformOrigin
      }
    },

    attatchSortedManifestData (state, payload)
    {
      var sortedManifest = payload;

      state.siteInfo = sortedManifest.siteInfo;
      state.scenes = sortedManifest.scenes;
      state.contentComponents = sortedManifest.contentComponents;
      state.surveyData = sortedManifest.surveyData;
      state.segmentDescriptions = sortedManifest.segmentDescriptions;
    }
  },

  actions:
  {
    loadManifest(context)
    {
      context.commit('setLoadingState', true);

      var manifestPath = config.manifestPath;

      var fetchManifestPromise = fetch(manifestPath, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-store',
        credentials: 'same-origin',
        headers: {
            // 'Content-Type': 'application/json'
        },
      })
      .then(
        (response) => {
            return response.json();
        }
      )


      fetchManifestPromise.then(
        (data) => {
          var manifestData = data;
          var sortedManifest = sortManifest(manifestData);

          console.log(sortedManifest)

          // NOTE: Add a minimum delay to ensure the loading screen dosen't "flash" awkwardly on screen when loading happens instantly.
          var delayPromise = new Promise((resolve/*, reject*/)=>{
            setTimeout(() => {
                resolve();
            }, 500);
          });

          // NOTE: Preload images here
          var imageUrlsToPreload = [];
          sortedManifest.scenes.forEach((scene)=>{
            if (scene.data && scene.data.backgroundImage)
            {
              var imageSrc = getUmbracoMediaItemSrc(scene.data.backgroundImage);
              imageUrlsToPreload.push(imageSrc);
            }
          });
          if (sortedManifest.siteInfo.loginBackgroundImage)
          {
            imageUrlsToPreload.push(getUmbracoMediaItemSrc(sortedManifest.siteInfo.loginBackgroundImage));
          }
          if (sortedManifest.siteInfo.transitionVideoFromLoginToHomePosterImage)
          {
            imageUrlsToPreload.push(getUmbracoMediaItemSrc(sortedManifest.siteInfo.transitionVideoFromLoginToHomePosterImage));
          }
          if (sortedManifest.siteInfo.transitionVideoFromHomeToProfilePosterImage)
          {
            imageUrlsToPreload.push(getUmbracoMediaItemSrc(sortedManifest.siteInfo.transitionVideoFromHomeToProfilePosterImage));
          }

          var preloadImagesPromise = preloadImages(imageUrlsToPreload, true);
          var preloadSystemImagesPromise = preloadImages(config.systemImagesToPreload, false);

          Promise.all([preloadImagesPromise, preloadSystemImagesPromise, delayPromise])
            .then(()=>{
              context.commit('attatchSortedManifestData', sortedManifest);
              context.commit('setLoadingState', false);
            });

        });
    }
  }

});

// NOTE: Iterate the manifest data and map it to a more convenient structure
function sortManifest(manifestData)
{
  var sortedManifest = {
    siteInfo: {},
    scenes: [],
    contentComponents: [],
    surveyData: [],
    segmentDescriptions: {}
  };

  var expoRoot = null;
  if (manifestData.custom && manifestData.custom.length > 0 && manifestData.custom[0].type === 'expoSite')
  {
    expoRoot = manifestData.custom[0];
    sortedManifest.siteInfo = expoRoot.data;
  }
  else
  {
    // NOTE: Throw an error?
  }
  
  expoRoot.Children.forEach((node)=>{

    // NOTE: Get scenes
    if (node.type === 'sceneRoot')
    {
      var scenes = []
      var contentComponents = [];

      recursivelySortScenes(node, null, scenes, contentComponents);

      sortedManifest.scenes = scenes;
      sortedManifest.contentComponents = contentComponents;
    }
    else if (node.type === 'statisticalData')
    {
      // NOTE: Swap keys for theEnthusiasts and healthyFeelGoods, since the data in these was delivered to us swapped.
      // NOTE: I AM REPEATING THIS A BUNCH OF TIME TO MAKE IT VERY CLEAR:
      // NOTE: WE ARE SWAPPING VALUES FOR ENTHUSASTS AND HEALTHY FEEL GOODS HERE (Because they were mixed up in the data we recieved)
      // NOTE: WE ARE SWAPPING VALUES FOR ENTHUSASTS AND HEALTHY FEEL GOODS HERE (Because they were mixed up in the data we recieved)
      // NOTE: WE ARE SWAPPING VALUES FOR ENTHUSASTS AND HEALTHY FEEL GOODS HERE (Because they were mixed up in the data we recieved)
      // NOTE: WE ARE SWAPPING VALUES FOR ENTHUSASTS AND HEALTHY FEEL GOODS HERE (Because they were mixed up in the data we recieved)
      // NOTE: WE ARE SWAPPING VALUES FOR ENTHUSASTS AND HEALTHY FEEL GOODS HERE (Because they were mixed up in the data we recieved)
      // NOTE: WE ARE SWAPPING VALUES FOR ENTHUSASTS AND HEALTHY FEEL GOODS HERE (Because they were mixed up in the data we recieved)
      // NOTE: WE ARE SWAPPING VALUES FOR ENTHUSASTS AND HEALTHY FEEL GOODS HERE (Because they were mixed up in the data we recieved)
      // NOTE: WE ARE SWAPPING VALUES FOR ENTHUSASTS AND HEALTHY FEEL GOODS HERE (Because they were mixed up in the data we recieved)
      // NOTE: WE ARE SWAPPING VALUES FOR ENTHUSASTS AND HEALTHY FEEL GOODS HERE (Because they were mixed up in the data we recieved)
      // NOTE: WE ARE SWAPPING VALUES FOR ENTHUSASTS AND HEALTHY FEEL GOODS HERE (Because they were mixed up in the data we recieved)
      // NOTE: (They keys in the CMS are still incorrect and should remain that way, but the names (not properties) have been updated to reflect the actual contents)
      var surveyDataWhereKeysForTheEnthusiastAndHealthyFeelGoodsHaveBeenSwappedBack = [];
      if (node.Children)
      {
        node.Children.forEach((table)=>{
          surveyDataWhereKeysForTheEnthusiastAndHealthyFeelGoodsHaveBeenSwappedBack.push(table);

          if (table.data && table.data.fields)
          {
            table.data.fields.forEach((question)=>{

              if (question.fields)
              {
                question.fields.forEach((regionalAnswers)=>{

                  // NOTE: Swap the two fields here
                  let healthyFeelGoodsAnswersSavedAsEnthusiasts = regionalAnswers.theEnthusiasts;
                  let enthusiastsAnswersSavedAsHealthyFeelGoods = regionalAnswers.healthyFeelGoods;

                  regionalAnswers.healthyFeelGoods = healthyFeelGoodsAnswersSavedAsEnthusiasts;
                  regionalAnswers.theEnthusiasts = enthusiastsAnswersSavedAsHealthyFeelGoods;
                });
              }

              // if same problem in 2022
              if (question.fields2022)
              {
                question.fields2022.forEach((regionalAnswers2022)=>{

                  // NOTE: Swap the two fields here
                  let healthyFeelGoodsAnswersSavedAsEnthusiasts = regionalAnswers2022.theEnthusiasts;
                  let enthusiastsAnswersSavedAsHealthyFeelGoods = regionalAnswers2022.healthyFeelGoods;

                  regionalAnswers2022.healthyFeelGoods = healthyFeelGoodsAnswersSavedAsEnthusiasts;
                  regionalAnswers2022.theEnthusiasts = enthusiastsAnswersSavedAsHealthyFeelGoods;
                });
              }

            });
          }
        });
      }

      sortedManifest.surveyData = surveyDataWhereKeysForTheEnthusiastAndHealthyFeelGoodsHaveBeenSwappedBack;
    }
    else if (node.type === 'segmentDescriptions')
    {
      sortedManifest.segmentDescriptions = node.data;
    }

  });

  return sortedManifest;
}

function recursivelySortScenes(node, parentScene, arrayOfScenes, arrayOfContentComponents)
{
  var scene = {
    id: node.id,
    parentId: (parentScene ? parentScene.id : null),
    slug: node.data.slug,
    path: (parentScene ? (parentScene.path + node.data.slug + '/') : '/'),
    title: node.data.title,
    type: node.type,
    sceneComponents: node.data.sceneComponents,
    data: node.data // NOTE: Just add all remaining data here
  };
  arrayOfScenes.push(scene);

  if (node.Children)
  {
    node.Children.forEach((childNode)=>{
      if (childNode.type == "scene")
      {
        recursivelySortScenes(childNode, scene, arrayOfScenes, arrayOfContentComponents);
      }
      // NOTE: Assume everything else is a content component
      else
      {
        var contentComponent = {
          id: childNode.id,
          scene: scene,
          slug: childNode.data.slug,
          path: scene.path + childNode.data.slug + '/',
          type: childNode.type,
          data: childNode.data // NOTE: Just add all remaining data here
        }
        arrayOfContentComponents.push(contentComponent);
      }
    });
  }

  return arrayOfScenes;
}