<template>
    <div class="deep-data-link" :class="'deep-data-link--color-'+color" v-if="!this.isHidden">

      <div class="deep-data-link__text" v-if="textToShow">

        <div class="deep-data-link__text-content" v-html="textToShow"></div>
        <div class="deep-data-link__text-background">
          <div class="deep-data-link__text-corner"></div>
        </div>
      </div>

      <router-link class="deep-data-link__link" :to="{path: $route.path + 'deep-data/', query: $route.query}">
        <span v-html="s_('deepDataLink.heading')"></span>
      </router-link>
      
    </div>
</template>

<script>
import {getUrlInfo} from '@/helpers/navigationHelper';
import eventBus from '@/helpers/eventBus';
import * as languageHelper from '@/helpers/languageHelper';

export default {
  name: 'DeepDataLink',
  props: {
    text: String
  },
  data()
  {
    return {
      externalText: '',
      externalTableId: null,
      isHidden: false
    }
  },
  computed: {
    textToShow()
    {
      return (this.externalText) ? this.externalText : this.text;
    },
    color()
    {
      var color = 'red';

      var profile = getUrlInfo().profile;

      if (profile === 'performance-nutrition') {color = 'red'}
      else if (profile === 'active-nutrition') {color = 'blue'}
      else if (profile === 'lifestyle-nutrition') {color = 'yellow'}

      return color;
    }
  },
  methods: {
    s_(stringId)
    {
        return languageHelper.getSystemString(stringId);
    },
  },
  created()
  {
     this.events = {
      onSetText: (data)=>{
        this.externalText = data.text;
        this.externalTableId = data.tableId;
        this.$store.commit('setDeepDataAnchorTabelId', data.tableId);
      },
      onClearText: ()=>{
        this.externalText = '';
      },
      onHide: ()=>{
        this.isHidden = true;
      },
      onUnHide: ()=>{
        this.isHidden = false;
      }
    }
    eventBus.on('deepDataLink.setText', this.events.onSetText);
    eventBus.on('deepDataLink.clearText', this.events.onClearText);
    eventBus.on('deepDataLink.hide', this.events.onHide);
    eventBus.on('deepDataLink.unHide', this.events.onUnHide);
  },
  unmounted()
  {
    eventBus.off('deepDataLink.setText', this.events.onSetText);
    eventBus.off('deepDataLink.clearText', this.events.onClearText);
    eventBus.off('deepDataLink.hide', this.events.onHide);
    eventBus.off('deepDataLink.unHide', this.events.onUnHide);
  },
}
</script>