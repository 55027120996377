<template>
    <div class="video-transitions">

        <div class="video-transitions__active-transition" v-if="currentVideo">
          <Button class="video-transitions__skip-btn" @click="handleVideoSkip">{{s_('videoTransitions.skipBtn')}}</Button>

          <PreloadedImage v-if="currentVideo.image" :src="mediaUrl(currentVideo.image)"/>
          <video 
            v-if="currentVideo.video"
            :src="mediaUrl(currentVideo.video)"
            muted
            autoplay
            playsinline
            webkit-playsinline
            type="video/mp4"
            @contextmenu.prevent="" @ended="handleVideoEnd"></video>
        </div>

        <div class="video-transitions__preload-videos">

          <video 
            v-if="transitionVideoFromLoginToHome"
            :src="mediaUrl(transitionVideoFromLoginToHome)"
            muted
            playsinline
            webkit-playsinline
            type="video/mp4"
            @contextmenu.prevent=""></video>

          <video 
            v-if="transitionVideoFromLoginToUKHome"
            :src="mediaUrl(transitionVideoFromLoginToUKHome)"
            muted
            playsinline
            webkit-playsinline
            type="video/mp4"
            @contextmenu.prevent=""></video>

          <video 
            v-if="transitionVideoFromLoginToUSAHome"
            :src="mediaUrl(transitionVideoFromLoginToUSAHome)"
            muted
            playsinline
            webkit-playsinline
            type="video/mp4"
            @contextmenu.prevent=""></video>

          <video 
            v-if="transitionVideoFromLoginToChinaHome"
            :src="mediaUrl(transitionVideoFromLoginToChinaHome)"
            muted
            playsinline
            webkit-playsinline
            type="video/mp4"
            @contextmenu.prevent=""></video>

          <video 
            v-if="transitionVideoFromLoginToChinaHomeZh"
            :src="mediaUrl(transitionVideoFromLoginToChinaHomeZh)"
            muted
            playsinline
            webkit-playsinline
            type="video/mp4"
            @contextmenu.prevent=""></video>

          <video 
            v-if="transitionVideoFromLoginToJapanHome"
            :src="mediaUrl(transitionVideoFromLoginToJapanHome)"
            muted
            playsinline
            webkit-playsinline
            type="video/mp4"
            @contextmenu.prevent=""></video>

          <video 
            v-if="transitionVideoFromLoginToBrazilHome"
            :src="mediaUrl(transitionVideoFromLoginToBrazilHome)"
            muted
            playsinline
            webkit-playsinline
            type="video/mp4"
            @contextmenu.prevent=""></video>

          <video 
            v-if="transitionVideoFromLoginToBrazilHomePt"
            :src="mediaUrl(transitionVideoFromLoginToBrazilHomePt)"
            muted
            playsinline
            webkit-playsinline
            type="video/mp4"
            @contextmenu.prevent=""></video>

          <video 
            v-if="transitionVideoFromHomeToProfile"
            :src="mediaUrl(transitionVideoFromHomeToProfile)"
            muted
            playsinline
            webkit-playsinline
            type="video/mp4"
            @contextmenu.prevent=""></video>


        </div>

    </div>

</template>

<script>
import eventBus from '@/helpers/eventBus';
import { getUmbracoMediaItemSrc } from '@/helpers/assetHelper';
import PreloadedImage from '@/components/PreloadedImage'
import Button from '@/components/Button'
import * as languageHelper from '@/helpers/languageHelper';

export default {
  name: 'VideoTransitions',
  components: {PreloadedImage, Button},
  data()
  {
      return {
          currentVideoTransitionId: null,
          transitionVideoFromLoginToHomePosterImage: this.$store.state.siteInfo.transitionVideoFromLoginToHomePosterImage,
          transitionVideoFromLoginToHome: this.$store.state.siteInfo.transitionVideoFromLoginToHome,
          transitionVideoFromLoginToHomeZh: this.$store.state.siteInfo.transitionVideoFromLoginToHomeZh,
          transitionVideoFromLoginToHomePt: this.$store.state.siteInfo.transitionVideoFromLoginToHomePt,

          transitionVideoFromLoginToUKHome: this.$store.state.siteInfo.transitionVideoFromLoginToUKHome,
          transitionVideoFromLoginToUSAHome: this.$store.state.siteInfo.transitionVideoFromLoginToUSAHome,
          transitionVideoFromLoginToChinaHome: this.$store.state.siteInfo.transitionVideoFromLoginToChinaHome,
          transitionVideoFromLoginToChinaHomeZh: this.$store.state.siteInfo.transitionVideoFromLoginToChinaHomeZh,
          transitionVideoFromLoginToJapanHome: this.$store.state.siteInfo.transitionVideoFromLoginToJapanHome,
          transitionVideoFromLoginToBrazilHome: this.$store.state.siteInfo.transitionVideoFromLoginToBrazilHome,
          transitionVideoFromLoginToBrazilHomePt: this.$store.state.siteInfo.transitionVideoFromLoginToBrazilHomePt,

          transitionVideoFromHomeToProfilePosterImage: this.$store.state.siteInfo.transitionVideoFromHomeToProfilePosterImage,
          transitionVideoFromHomeToProfile: this.$store.state.siteInfo.transitionVideoFromHomeToProfile,
      }
  },
  methods:
  {
    s_(stringId)
    {
      return languageHelper.getSystemString(stringId);
    },
    mediaUrl(image)
    {
      return getUmbracoMediaItemSrc(image);
    },
    handleVideoSkip()
    {
      this.currentVideoTransitionId = null
    },
    handleVideoEnd()
    {
      this.currentVideoTransitionId = null
    }
  },
  computed:
  {
    currentVideo()
    {
      var result = false;

      var currentLangauge = languageHelper.getLanguage();

      console.log(this.currentVideoTransitionId)

      if (this.currentVideoTransitionId === 'login-video-global')
      {
        result = {
          image: this.transitionVideoFromLoginToHomePosterImage,
          video: this.transitionVideoFromLoginToHome,
        }
      }
      else if (this.currentVideoTransitionId === 'login-video-uk')
      {
        result = {
          image: this.transitionVideoFromLoginToHomePosterImage,
          video: this.transitionVideoFromLoginToUKHome,
        }
      }
      else if (this.currentVideoTransitionId === 'login-video-usa')
      {
        result = {
          image: this.transitionVideoFromLoginToHomePosterImage,
          video: this.transitionVideoFromLoginToUSAHome,
        }
      }
      else if (this.currentVideoTransitionId === 'login-video-china')
      {
        if (currentLangauge === 'en')
        {
          result = {
            image: this.transitionVideoFromLoginToHomePosterImage,
            video: this.transitionVideoFromLoginToChinaHome,
          }
        }
        else if (currentLangauge === 'zh')
        {
          result = {
            image: this.transitionVideoFromLoginToHomePosterImage,
            video: this.transitionVideoFromLoginToChinaHomeZh,
          }
        }
      }
      else if (this.currentVideoTransitionId === 'login-video-japan')
      {
        result = {
          image: this.transitionVideoFromLoginToHomePosterImage,
          video: this.transitionVideoFromLoginToJapanHome,
        }
      }
      else if (this.currentVideoTransitionId === 'login-video-brazil')
      {
        if (currentLangauge === 'en')
        {
          result = {
            image: this.transitionVideoFromLoginToHomePosterImage,
            video: this.transitionVideoFromLoginToBrazilHome,
          }
        }
        if (currentLangauge === 'pt')
        {
          result = {
            image: this.transitionVideoFromLoginToHomePosterImage,
            video: this.transitionVideoFromLoginToBrazilHomePt,
          }
        }
      }
      else if (this.currentVideoTransitionId === 'profiles-city-video')
      {
        result = {
          image: this.transitionVideoFromHomeToProfilePosterImage,
          video: this.transitionVideoFromHomeToProfile,
        }
      }

      return result;
    }
  },
  created()
  {
    this.unsubscribe = this.$store.subscribe((mutation, state) =>
    {
      // NOTE: React to the manifest being ready for use
      if (mutation.type === 'attatchSortedManifestData')
      {
        this.transitionVideoFromLoginToHomePosterImage = state.siteInfo.transitionVideoFromLoginToHomePosterImage;
        this.transitionVideoFromLoginToHome = state.siteInfo.transitionVideoFromLoginToHome;
        this.transitionVideoFromLoginToHomeZh = state.siteInfo.transitionVideoFromLoginToHomeZh;
        this.transitionVideoFromLoginToHomePt = state.siteInfo.transitionVideoFromLoginToHomePt;

        this.transitionVideoFromLoginToUKHome = state.siteInfo.transitionVideoFromLoginToUKHome;
        this.transitionVideoFromLoginToUSAHome = state.siteInfo.transitionVideoFromLoginToUSAHome;
        this.transitionVideoFromLoginToChinaHome = state.siteInfo.transitionVideoFromLoginToChinaHome;
        this.transitionVideoFromLoginToChinaHomeZh = state.siteInfo.transitionVideoFromLoginToChinaHomeZh;
        this.transitionVideoFromLoginToJapanHome = state.siteInfo.transitionVideoFromLoginToJapanHome;
        this.transitionVideoFromLoginToBrazilHome = state.siteInfo.transitionVideoFromLoginToBrazilHome;
        this.transitionVideoFromLoginToBrazilHomePt = state.siteInfo.transitionVideoFromLoginToBrazilHomePt;

        this.transitionVideoFromHomeToProfilePosterImage = state.siteInfo.transitionVideoFromHomeToProfilePosterImage;
        this.transitionVideoFromHomeToProfile = state.siteInfo.transitionVideoFromHomeToProfile;
      }
    });

    this.events = {
      onTransitionTrigger: (videoTransitionId)=>{
        this.currentVideoTransitionId = videoTransitionId;
      }
    }
    eventBus.on('videoTransitionManager.trigger', this.events.onTransitionTrigger);
  },
  unmounted()
  {
    eventBus.off('videoTransitionManager.trigger', this.events.onTransitionTrigger);
    this.unsubscribe();
  }
}
</script>