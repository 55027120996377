<template>
    <div ref="scrollbar" class="scrollbar">
        <slot></slot>
    </div>
</template>

<script>
import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.css';

export default {
    mounted()
    {
        this.simpleBar = new SimpleBar(this.$refs.scrollbar, { autoHide: false });

        this.events = {
            handleScroll: (event) => {
                this.$emit('scroll', event)
            }
        }

        var scrollContainer = this.$refs.scrollbar.querySelector('.simplebar-content-wrapper');

        scrollContainer.addEventListener('scroll', this.events.handleScroll);

    },
    beforeUnmount()
    {
        var scrollContainer = this.$refs.scrollbar.querySelector('.simplebar-content-wrapper');
        scrollContainer.removeEventListener('scroll', this.events.handleScroll);

        if (this.simpleBar)
        {
            this.simpleBar.unMount();
        }
    }
}
</script>