<template>
  <div class="popup" :class="['popup--style-'+type, 'popup--color-'+color, {'popup--opaque': opaque}]">
    <div class="popup__window">
        <div class="popup__inner">
            <div class="popup__close-btn" @click="$emit('close')"></div>
            <slot></slot>
        </div>
    </div>
    <div class="popup__overlay" @click="$emit('close')"></div>
  </div>
</template>

<script>
import eventBus from '@/helpers/eventBus';

export default {
  name: 'Popup',
  props: {
      type: String,
      color: String,
      opaque: Boolean
  },
  mounted()
  {
    eventBus.emit('deepDataLink.hide');
    eventBus.emit('hotspot.hide');
  },
  unmounted()
  {
    eventBus.emit('deepDataLink.unHide');
    eventBus.emit('hotspot.unHide');
  },
}
</script>