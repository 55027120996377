<template>


  <div v-if="!hideSelf" class="light-data" :style="['left:'+x+'px;top:'+y+'px;']" :class="['light-data--hotspot-direction-'+direction]">
  
    <div v-if="lightDataType !== 'quote'" class="hotspot-button" :class="['hotspot-button--direction-'+direction, 'hotspot-button--color-'+color, {'hotspot-button--icon-close': isOpen}]">
        <div
          class="hotspot-button__inner" @click="onClick">
          <div class="hotspot-button__icon"></div>
          <div class="hotspot-button__title">{{hotspotTitle}}</div>
        </div>
    </div>

    <div v-else-if="lightDataType === 'quote'" class="quote-bubble" :class="['quote-bubble--direction-'+bubbleDirection, 'quote-bubble--color-'+color]">

      <div class="quote-bubble__button" v-if="!isOpen" @click="onClick"></div>

      <div class="quote-bubble__large-bubble" v-if="isOpen">
        <div class="quote-bubble__content" v-html="quoteText"></div>
        <div class="quote-bubble__close-button" @click="onClick"></div>
      </div>

    </div>
  

    <div class="light-data__element" :class="'light-data__element--'+lightDataType" v-if="isOpen">

      <BarChart v-if="lightDataType === 'bars'" :barLengthBase="dataBarLengthBase" :barLengthPercentage="dataBarLengthPercentage" :data="chartData" :color="color"/>

      <PieChart v-if="lightDataType === 'pie'" :barLength="dataBarLengthBase" :data="chartData" :color="color"/>

    </div>

  </div>

  <Popup v-if="lightDataType === 'popup' && isOpen" :color="color" :type="popupType" @close="onClick">
    <div class="emotional-drivers" :class="'emotional-drivers--'+emotionalDriversLayout">

      <div class="emotional-drivers__item emotional-drivers__item--conclusion">
        <div class="emotional-drivers__text" v-html="deepDataLinkText"></div>
      </div>

      <div class="emotional-drivers__item">
        <div class="emotional-drivers__image" v-if="emotionalDrivers1Icon" :style="'background-image: url('+mediaUrl(emotionalDrivers1Icon)+')'"></div>
        <div class="emotional-drivers__image" v-else></div>
        <div class="emotional-drivers__title" v-html="emotionalDrivers1Title"></div>
        <div class="emotional-drivers__text" v-html="emotionalDrivers1Text"></div>
        <div class="emotional-drivers__percentage percentage-blob" :class="['percentage-blob--'+color, 'percentage-blob--'+emotionalDriversLayout]"> 
            <div class="percentage-blob__value">{{emotionalDrivers1Percentage}}</div>
            <div class="percentage-blob__symbol">%</div>
        </div>
      </div>

      <div class="emotional-drivers__item">
        <div class="emotional-drivers__image" v-if="emotionalDrivers2Icon" :style="'background-image: url('+mediaUrl(emotionalDrivers2Icon)+')'"></div>
        <div class="emotional-drivers__image" v-else></div>
        <div class="emotional-drivers__title" v-html="emotionalDrivers2Title"></div>
        <div class="emotional-drivers__text" v-html="emotionalDrivers2Text"></div>
        <div class="emotional-drivers__percentage percentage-blob" :class="['percentage-blob--'+color, 'percentage-blob--'+emotionalDriversLayout]"> 
            <div class="percentage-blob__value">{{emotionalDrivers2Percentage}}</div>
            <div class="percentage-blob__symbol">%</div>
        </div>
      </div>

      <div class="emotional-drivers__item">
        <div class="emotional-drivers__image" v-if="emotionalDrivers3Icon" :style="'background-image: url('+mediaUrl(emotionalDrivers3Icon)+')'"></div>
        <div class="emotional-drivers__image" v-else></div>
        <div class="emotional-drivers__title" v-html="emotionalDrivers3Title"></div>
        <div class="emotional-drivers__text" v-html="emotionalDrivers3Text"></div>

        <div class="emotional-drivers__percentage percentage-blob" :class="['percentage-blob--'+color, 'percentage-blob--'+emotionalDriversLayout]"> 
            <div class="percentage-blob__value">{{emotionalDrivers3Percentage}}</div>
            <div class="percentage-blob__symbol">%</div>
        </div>
      </div>

    </div>
  </Popup>

</template>

<script>
import {getUrlInfo} from '@/helpers/navigationHelper';
import Popup from '@/components/Popup';
import PieChart from '@/components/PieChart';
import BarChart from '@/components/BarChart';
import eventBus from '@/helpers/eventBus';
import { getUmbracoMediaItemSrc } from '@/helpers/assetHelper';
import * as languageHelper from '@/helpers/languageHelper';

export default {
  name: 'LightData',
  components: {Popup, PieChart, BarChart},
  props:
  {
    titleID: String,
    hotspotTitle: String,
    x: Number,
    y: Number,
    direction: String,
    lightDataType: String,
    dataReference: Number,
    dataValuesToInclude: Array,
    dataBarLengthBase: Number,
    dataBarLengthPercentage: Number,
    deepDataLinkText: String,
    quoteText: String,
    emotionalDrivers1Title: String,
    emotionalDrivers1Text: String,
    emotionalDrivers1Icon: Object,
    emotionalDrivers1Percentage: Number,
    emotionalDrivers2Title: String,
    emotionalDrivers2Text: String,
    emotionalDrivers2Icon: Object,
    emotionalDrivers2Percentage: Number,
    emotionalDrivers3Title: String,
    emotionalDrivers3Text: String,
    emotionalDrivers3Icon: Object,
    emotionalDrivers3Percentage: Number
  },
  data()
  {
    return {
      tableData: [],
      hideAllHotspots: false
    }
  },
  computed:
  {
    emotionalDriversLayout()
    {
      var result = 'default';
      if (!this.emotionalDrivers1Icon || !this.emotionalDrivers2Icon || !this.emotionalDrivers3Icon || !this.emotionalDrivers1Title || !this.emotionalDrivers2Title || !this.emotionalDrivers3Title)
      {
        result = 'alternative';
      }
      return result;
    },
    popupType()
    {
      var result = 'light-data';
      if (this.emotionalDriversLayout === 'alternative')
      {
        result = 'light-data-alternative';
      }
      return result;
    },
    bubbleDirection()
    {
      // NOTE: Bubble direction defaults to opposite of hotspots...
      var result = 'left';
      if (this.direction === 'left') result = 'right';
      return result;
    },
    chartData()
    {
      var result = [];

      this.selectedDataValues.forEach((row)=>{
        if (row.fields2022.find(({region})=>region === this.region))
        {
          var entry = {
            title: this.getTranslatedProp(row.title, row.titleZh, row.titlePt),
            value: row.fields2022.find(({region})=>region === this.region)[this.segment]
          };
          result.push(entry);
        }
      });

      // NOTE: Sort entries by value, high to low
      result.sort((a,b) => (a.value < b.value) ? 1 : ((b.value < a.value) ? -1 : 0));

      return result;
    },
    color()
    {
      var color = 'grey';

      var profile = getUrlInfo().profile;

      if (profile === 'performance-nutrition') {color = 'red'}
      else if (profile === 'active-nutrition') {color = 'blue'}
      else if (profile === 'lifestyle-nutrition') {color = 'yellow'}


      return color;
    },
    hideSelf()
    {
      var shouldHideSelf = false;

      if (this.hideAllHotspots || !this.isOpen && this.$route.query.lightData)
      {
        shouldHideSelf = true;
      }

      return shouldHideSelf;
    },
    isOpen()
    {
      return (this.$route.query.lightData && this.$route.query.lightData === this.titleID) ? true : false;
    },
    selectedDataValues()
    {
      var dataValues = [];

      this.dataValuesToInclude.forEach((rowIndex)=>{
        var rowIndexInt = parseInt(rowIndex) -1;
        
        if (this.tableData.data && this.tableData.data[rowIndexInt])
        {
          dataValues.push(this.tableData.data[rowIndexInt]);
        }
      });

      return dataValues;
    },
    region()
    {
      return getUrlInfo().region;
    },
    segment()
    {
      var profile = getUrlInfo().profile;

      if (profile === 'performance-nutrition') {profile = 'theEnthusiasts'}
      else if (profile === 'active-nutrition') {profile = 'easyHealth'}
      else if (profile === 'lifestyle-nutrition') {profile = 'healthyFeelGoods'}

      return profile;
    }
  },
  watch:
  {
    isOpen(newValue, oldValue)
    {
      if (newValue === true && oldValue === false)
      {
        this.emitSetDeepDataLinkText();
      }
      else if (oldValue === true && newValue === false)
      {
        this.emitClearDeepDataLinkText();
      }
    },
  },
  created()
  {
    this.$store.state.surveyData.forEach((questionData)=>{
      if (this.dataReference === questionData.id)
      {
        this.tableData = {
          question: questionData.data.question,
          data: questionData.data.fields
        }
      }
    });

    this.events = {
      onHide: ()=>{
        this.hideAllHotspots = true;
      },
      onUnHide: ()=>{
        this.hideAllHotspots = false;
      }
    }

    eventBus.on('hotspot.hide', this.events.onHide);
    eventBus.on('hotspot.unHide', this.events.onUnHide);

  },
  mounted()
  {
    if (this.isOpen)
    {
      this.emitSetDeepDataLinkText();
    }
  },
  unmounted()
  {
    eventBus.off('hotspot.hide', this.events.onHide);
    eventBus.off('hotspot.unHide', this.events.onUnHide);
  },
  methods:
  {
    getTranslatedProp(propEn, propZh, propPt)
    {
      var currentLangauge = languageHelper.getLanguage();
      
      if (currentLangauge === 'zh')
      {
        return propZh;
      }
      else if (currentLangauge === 'pt')
      {
        return propPt;
      }

      return propEn;
    },
    mediaUrl(image)
    {
      return getUmbracoMediaItemSrc(image);
    },
    emitSetDeepDataLinkText()
    {
      eventBus.emit('deepDataLink.setText', {text: this.deepDataLinkText, tableId: this.dataReference});
      if (this.lightDataType !== 'bars' && this.lightDataType !== 'pie')
      {
        eventBus.emit('deepDataLink.hide');
      }
    },
    emitClearDeepDataLinkText()
    {
      eventBus.emit('deepDataLink.clearText');
      eventBus.emit('deepDataLink.unHide');
    },
    onClick()
    {
      // NOTE: Close if already open
      if (this.$route.query.lightData && this.$route.query.lightData === this.titleID)
      {
        // NOTE: Make sure to retain all other params here
        /* eslint-disable no-unused-vars */
        var { lightData, ...queryMinusSelectedProfile } = this.$route.query;
        /* eslint-enable no-unused-vars */

        this.$router.replace({ path: this.$route.path, query: queryMinusSelectedProfile});
      }
      // NOTE: Open if not open
      else
      {
        this.$router.replace({ path: this.$route.path, query: {lightData: this.titleID, ...this.$route.query}});
      }
    }
  }
}
</script>
