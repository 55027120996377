<template>
  <transition name="fade">
    <div class="hotspot-button"
    :class="[
        {'_hotspot-button--has-transition': hasTransition},
        {'_hotspot-button--has-pulse': hasPulse},
        'hotspot-button--icon-'+icon,
        'hotspot-button--direction-'+direction,
        'hotspot-button--color-'+colorToUse,
        {'hotspot-button--icon-close': isOpen}
    ]"
    :style="['left:'+x+'px;top:'+y+'px;', '_transition-delay: '+transitionDelayMs+'ms']">
      <div
        v-if="(this.targetId || this.type === 'clickable') && !isHidden"
        class="hotspot-button__inner"
        :style="{'width': (fixedWidth) ? fixedWidth+'px' : 'auto'}"
        @click="onClick">
        <div class="hotspot-button__icon" :style="['animation-duration: '+pulseIntervalMs+'ms', 'animation-delay: '+pulseIntervalMs+'ms']"></div>
        <div class="hotspot-button__title" v-html="title"></div>
      </div>
      <a
        v-else-if="this.externalUrl  && !isHidden"
        class="hotspot-button__inner"
        :style="{'width': (fixedWidth) ? fixedWidth+'px' : 'auto'}"
        :href="this.externalUrl"
        target="_blank">
        <div class="hotspot-button__icon" :style="['animation-duration: '+pulseIntervalMs+'ms', 'animation-delay: '+pulseIntervalMs+'ms']"></div>
        <div class="hotspot-button__title" v-html="title"></div>
      </a>
    </div>
  </transition>
</template>

<script>
import {goToPathByID, getUrlInfo} from '@/helpers/navigationHelper';
import eventBus from '@/helpers/eventBus';

export default {
  name: 'HotspotButton',
  props:
  {
    type: String,
    title: String,
    x: Number,
    y: Number,
    targetPath: String,
    targetId: Number,
    externalUrl: String,
    hasTransition: Boolean,
    transitionDelayMs: Number,
    hasPulse: Boolean,
    pulseIntervalMs: Number,
    icon: String,
    direction: String,
    color: String,
    fixedWidth: Number,
    isOpen: Boolean
  },
  data()
  {
    return {
      hideAllHotspots: false
    }
  },
  computed:
  {
    isHidden()
    {
      var shouldBeHidden = false;
      if (this.hideAllHotspots || this.$route.query.lightData)
      {
        shouldBeHidden = true;
      }
      return shouldBeHidden;
    },
    colorToUse()
    {
      var color = this.color;
      if (color)
      {
        return color;
      }
      else
      {
        var profile = getUrlInfo().profile;

        if (profile === 'performance-nutrition') {color = 'red'}
        else if (profile === 'active-nutrition') {color = 'blue'}
        else if (profile === 'lifestyle-nutrition') {color = 'yellow'}
      }
      return color;
    }
  },
  methods:
  {
    onClick()
    {
      if (this.type === 'clickable')
      {
        // NOTE: do nothing
      }
      else if (this.targetId)
      {
          var transformOrigin = (this.x / 1920)*100+'% ' + (this.y / 1080)*100+'%';
          this.$store.commit('setSceneTransitionEffect', {type: 'zoomAndFade', transformOrigin: transformOrigin});

          goToPathByID(this.targetId);
      }
    }
  },
  created()
  {
    this.events = {
      onHide: ()=>{
        this.hideAllHotspots = true;
      },
      onUnHide: ()=>{
        this.hideAllHotspots = false;
      }
    }

    eventBus.on('hotspot.hide', this.events.onHide);
    eventBus.on('hotspot.unHide', this.events.onUnHide);

  },
  unmounted()
  {
    eventBus.off('hotspot.hide', this.events.onHide);
    eventBus.off('hotspot.unHide', this.events.onUnHide);
  }
}
</script>
