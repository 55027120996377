import router from '@/router';

let systemStrings = {
    en: {
        'login.chooseLanguage': 'Choose Language',
        'login.chooseMarket': 'Choose Market',
        'login.login': 'Login',
        'login.password': 'Password',
        'login.wrongPassword': 'Wrong password',
        'login.pleaseTryAgain': 'Please try again',
        'videoTransitions.skipBtn': 'Skip Intro',
        'profileSelection.theEnthusiastHotspot': 'Performance nutrition',
        'profileSelection.easyHealthHotspot': 'Active nutrition',
        'profileSelection.healthyFeelGoodsHotspot': 'Lifestyle nutrition', 
        'profileSelection.whyDataMatters': 'Why data matters',
        'profileSelection.whyMethodology': 'Methodology',
        'profileSelection.meetEnthusiastBtn': '<span class="meet">Meet The</span>Performance nutrition<span> consumer</span>',
        'profileSelection.meetEasyHealth': '<span class="meet">Meet The</span>Active nutrition<span> consumer</span>',
        'profileSelection.meetHealthyFeelGoods': '<span class="meet">Meet The</span>Lifestyle nutrition<span> consumer</span>',
        'deepDataLink.heading': 'Deep Data',
        'deepData.heading': 'Deep Data',
        'deepData.showHide': 'Show/hide data',
        'deepData.headerTotal': 'Total',
        'deepData.headerAllOther': 'All Other',
        'deepData.headerEnthusiast': 'PER',
        'deepData.headerEasyHealth': 'ACT',
        'deepData.headerHealthyFeelGoods': 'LFS',
        'deepData.headerBrazil': 'Brazil',
        'deepData.headerChina': 'China',
        'deepData.headerJapan': 'Japan',
        'deepData.headerUk': 'UK',
        'deepData.headerUs': 'US',
        'profilesPopup.demographicHeading': 'Demographics',
        'profilesPopup.ofTheGlobalPop': 'Of the<br/>global<br/>population',
        'profilesPopup.ofTheUkPop': 'Of the UK<br/>population',
        'profilesPopup.ofTheUsPop': 'Of the US<br/>population',
        'profilesPopup.ofTheChinesePop': 'Of the<br/>Chinese<br/>population',
        'profilesPopup.ofTheJapanesePop': 'Of the<br/>Japanese<br/>population',
        'profilesPopup.ofTheBrazilianPop': 'Of the<br/>Brazilian<br/>population',
        'profileLocationTitle.enthusiastHome': 'Visiting the <span class="color"><strong>performance<br/>nutrition</strong> consumer</span> at home',
        'profileLocationTitle.enthusiastGym': 'When the <span class="color"><strong>performance<br/>nutrition</strong> consumer</span> exercise',
        'profileLocationTitle.enthusiastSupermarket': 'With the <span class="color"><strong>performance<br>nutrition</strong> consumer</span> at the<br/>supermarket',
        'profileLocationTitle.easyHealthHome': 'Visiting the <span class="color"><strong>active <br/>nutrition</strong> consumer</span> at home',
        'profileLocationTitle.easyHealthGym': 'When the <span class="color"><strong>active<br/>nutrition</strong> consumer</span> exercise',
        'profileLocationTitle.easyHealthSupermarket': 'With the<br><span class="color"><strong>active nutrition</strong><br> consumer</span> at the<br/>supermarket',
        'profileLocationTitle.healthyFeelGoodHome': 'Visiting the <span class="color"><strong>lifestyle<br/>nutrition</strong> consumer</span> at home',
        'profileLocationTitle.healthyFeelGoodenthusiastGym': 'When the <span class="color"><strong>lifestyle<br/>nutrition</strong> consumer</span> exercise',
        'profileLocationTitle.healthyFeelGoodSupermarket': 'With the <span class="color"><strong>lifestyle<br>nutrition</strong> consumer</span> at the<br/>supermarket',
    },
    zh: {
        'login.chooseLanguage': '选择语言',
        'login.chooseMarket': '选择市场',
        'login.login': '登录',
        'login.password': '密码',
        'login.wrongPassword': '密码错误',
        'login.pleaseTryAgain': '请再次输入',
        'videoTransitions.skipBtn': '跳过开始',
        'profileSelection.theEnthusiastHotspot': '<span>卓越营养消费群体</span>',
        'profileSelection.easyHealthHotspot': '<span>活力营养消费群体</span>',
        'profileSelection.healthyFeelGoodsHotspot': '<span>乐享营养消费群体</span>', 
        'profileSelection.whyDataMatters': '为什么数据很重要？',
        'profileSelection.whyMethodology': '方法论',
        'profileSelection.meetEnthusiastBtn': '卓越营养消费群体',
        'profileSelection.meetEasyHealth': '活力营养消费群体',
        'profileSelection.meetHealthyFeelGoods': '乐享营养消费群体',
        'deepDataLink.heading': '详细数据',
        'deepData.heading': '详细数据',
        'deepData.showHide': '显示/隐藏数据',
        'deepData.headerTotal': '全部',
        'deepData.headerAllOther': '全部其他',
        'deepData.headerEnthusiast': 'PER',
        'deepData.headerEasyHealth': 'ACT',
        'deepData.headerHealthyFeelGoods': 'LFS',
        'deepData.headerBrazil': '巴西',
        'deepData.headerChina': '中国',
        'deepData.headerJapan': '日本',
        'deepData.headerUk': '英国',
        'deepData.headerUs': '美国',
        'profilesPopup.demographicHeading': '群体画像',
        'profilesPopup.ofTheGlobalPop': '全球受访者',
        'profilesPopup.ofTheUkPop': '',
        'profilesPopup.ofTheUsPop': '',
        'profilesPopup.ofTheChinesePop': '的中国人口',
        'profilesPopup.ofTheJapanesePop': '',
        'profilesPopup.ofTheBrazilianPop': '',
        'profileLocationTitle.enthusiastHome': '<span class="color">卓越营养消费者</span>在家里',
        'profileLocationTitle.enthusiastGym': '<span class="color">卓越营养消费者</span>在锻炼',
        'profileLocationTitle.enthusiastSupermarket': '<span class="color">卓越营养消费者</span>在超市',
        'profileLocationTitle.easyHealthHome': '<span class="color">活力营养消费者</span>在家里',
        'profileLocationTitle.easyHealthGym': '<span class="color">活力营养消费者在锻炼',
        'profileLocationTitle.easyHealthSupermarket': '<span class="color">活力营养消费者</span>在超市',
        'profileLocationTitle.healthyFeelGoodHome': '<span class="color">乐享营养消费者</span>在家里',
        'profileLocationTitle.healthyFeelGoodenthusiastGym': '<span class="color">乐享营养消费者</span>在锻炼',
        'profileLocationTitle.healthyFeelGoodSupermarket': '<span class="color">乐享营养消费者</span>在超市',
    },
    pt: {
        'login.chooseLanguage': 'Escolha o idioma',
        'login.chooseMarket': 'Escolha o mercado',
        'login.login': 'Login',
        'login.password': 'Senha',
        'login.wrongPassword': 'Senha incorreta',
        'login.pleaseTryAgain': 'Tente novamente',
        'videoTransitions.skipBtn': 'Pular introdução',
        // 'profileSelection.theEnthusiastHotspot': 'Entusiasta',
        // 'profileSelection.easyHealthHotspot': 'Saúde Fácil',
        // 'profileSelection.healthyFeelGoodsHotspot': 'De Bem com a Saúde', 
        // 'profileSelection.whyDataMatters': 'Por que os dados importam',
        // 'profileSelection.whyMethodology': 'Metodologia',
        // 'profileSelection.meetEnthusiastBtn': 'Conheça o Entusiasta',
        // 'profileSelection.meetEasyHealth': 'Conheça o Saúde Fácil',
        // 'profileSelection.meetHealthyFeelGoods': 'Conheça o De Bem com a Saúde',
        'profileSelection.theEnthusiastHotspot': 'Performance nutrition',
        'profileSelection.easyHealthHotspot': 'Active nutrition',
        'profileSelection.healthyFeelGoodsHotspot': 'Lifestyle nutrition', 
        'profileSelection.whyDataMatters': 'Por que os dados importam',
        'profileSelection.whyMethodology': 'Metodologia',
        'profileSelection.meetEnthusiastBtn': '<strong>SAIBA MAIS</strong>',
        'profileSelection.meetEasyHealth': '<strong>SAIBA MAIS</strong>',
        'profileSelection.meetHealthyFeelGoods': '<strong>SAIBA MAIS</strong>',
        'deepDataLink.heading': 'Dados detalhados',
        'deepData.heading': 'Dados detalhados',
        'deepData.showHide': 'Exibir/ocultar dados',
        'deepData.headerTotal': 'Total',
        'deepData.headerAllOther': 'Todos os outros',
        'deepData.headerEnthusiast': 'PER',
        'deepData.headerEasyHealth': 'ACT',
        'deepData.headerHealthyFeelGoods': 'LFS',
        'deepData.headerBrazil': 'Brasil',
        'deepData.headerChina': 'China',
        'deepData.headerJapan': 'Japão',
        'deepData.headerUk': 'Reino Unido',
        'deepData.headerUs': 'EUA',
        'profilesPopup.demographicHeading': 'Dados demográficos',
        'profilesPopup.ofTheGlobalPop': 'Da<br/>população<br/>global',
        'profilesPopup.ofTheUkPop': '',
        'profilesPopup.ofTheUsPop': '',
        'profilesPopup.ofTheChinesePop': '',
        'profilesPopup.ofTheJapanesePop': '',
        'profilesPopup.ofTheBrazilianPop': 'dos brasileiros',
        // 'profileLocationTitle.enthusiastHome': 'Visitando <strong>O ENTUSIASTA</strong><br/>em casa',
        // 'profileLocationTitle.enthusiastGym': 'Quando <strong>O ENTUSIASTA</strong><br/>se exercita',
        // 'profileLocationTitle.enthusiastSupermarket': 'Com <strong>O ENTUSIASTA</strong><br/>no supermercado',
        // 'profileLocationTitle.easyHealthHome': 'Visitando <strong>O SAÚDE FÁCIL</strong><br/>em casa',
        // 'profileLocationTitle.easyHealthGym': 'Quando <strong>O SAÚDE FÁCIL</strong><br/>se exercita',
        // 'profileLocationTitle.easyHealthSupermarket': 'Com <strong>O SAÚDE FÁCIL</strong><br/>no supermercado',
        // 'profileLocationTitle.healthyFeelGoodHome': 'Visitando <strong>O DE BEM COM A SAÚDE</strong><br/>em casa',
        // 'profileLocationTitle.healthyFeelGoodenthusiastGym': 'Quando <strong>O DE BEM COM A SAÚDE</strong><br/>se exercita',
        // 'profileLocationTitle.healthyFeelGoodSupermarket': 'Com <strong>O DE BEM COM A SAÚDE</strong><br/>no supermercado',
        'profileLocationTitle.enthusiastHome': 'Visitando o <span class="color"><strong>performance<br/> nutrition</strong></span> em casa',
        'profileLocationTitle.enthusiastGym': '<span class="color"><strong>performance<br/>  nutrition</strong></span> se exercitando',
        'profileLocationTitle.enthusiastSupermarket': 'Com o <span class="color"><strong>performance<br/>  nutrition</span></strong> no supermercado',
        'profileLocationTitle.easyHealthHome': 'Visitando o <span class="color"><strong>active nutrition</strong></span><br>em casa',
        'profileLocationTitle.easyHealthGym': '<span class="color"><strong>active nutrition</strong></span><br/> se exercitando',
        'profileLocationTitle.easyHealthSupermarket': 'Com o <span class="color"><strong>active nutrition</strong></span><br/> no supermercado',
        'profileLocationTitle.healthyFeelGoodHome': 'Visitando o <span class="color"><strong>lifestyle nutrition</strong></span><br/>em casa',
        'profileLocationTitle.healthyFeelGoodenthusiastGym': '<span class="color"><strong>lifestyle nutrition </strong><br/></span> se exercitando',
        'profileLocationTitle.healthyFeelGoodSupermarket': 'Com o <span class="color"><strong>lifestyle nutrition</strong></span><br> no supermercado',
    }
}

function getLanguage()
{
    return router.currentRoute.value.query.lang;
}

function setLanguage(languageId)
{
    // TODO: Verify existence of language
    
    /* eslint-disable no-unused-vars */
    var { lang, ...queryMinusLang } = router.currentRoute.value.query;
    /* eslint-enable no-unused-vars */
    router.replace({path: router.currentRoute.value.path, query: {lang: languageId, ...queryMinusLang}});
}

function getSystemString(stringId)
{
    var result = '';
    
    var currentLanguageId = getLanguage();

    if (systemStrings[currentLanguageId] && systemStrings[currentLanguageId][stringId])
    {
        result = systemStrings[currentLanguageId][stringId];
    }
    else
    {
        // TODO: Error handling
    }

    return result;
}

export {
    getLanguage,
    setLanguage,
    getSystemString
}